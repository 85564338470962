import React, { useRef, useState } from "react";
import {
  IconButton,
  Box,
  InputBase,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DoneIcon from "@mui/icons-material/Done";
import AddAlarmIcon from "@mui/icons-material/AddAlarmRounded";
import AlarmOnIcon from "@mui/icons-material/AlarmOnRounded";
import LeftConfetti from "./interactions/left-confetti";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

import ItemStreak from "./item-streak";
import {
  FILL_DELAY_MS,
  LocalAlarm,
  SERIF_FAMILY,
  SQUARE_SIZE,
  getApproximateLengthWidthAdjusted,
} from "../util";

const AlarmModal = React.lazy(() => import("./alarm-modal"));

const MemoizedConfetti = React.memo(LeftConfetti);
const findIndex = (sortlyLevels, id) => {
  return sortlyLevels.findIndex((item) => item.id === id);
};

const HabitRow = ({
  id,
  depth,
  name,
  isNew = false,
  value = false,
  hasChildren = false,
  isCollapsed = false,
  hasAddHabit = false,
  stability = [false],
  localAlarm = null,
  hidden = false,
  handlers,
  nowLocalDateStr,
  sortlyLevels,
  showAddHabitButtons,
  historyDatesToShow,
  hideEntries,
  lowerEntries = false,
}) => {
  const {
    handleChangeName,
    handleChangeValue,
    handleDelete,
    handleChangeAlarm,
    handleEditing,
  } = handlers;

  const confParentRef = useRef<HTMLInputElement>();

  const [state, setState] = useState({
    isEditing: isNew,
    runConfetti: false,
  });
  const [checkedPos, setCheckedPos] = useState([0, 0]);
  const [localName, setLocalName] = useState(name);

  const [openAlarmModal, setOpenAlarmModal] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleChangeChecked = (newValue) => {
    handleChangeValue(id, newValue);

    if (!state.runConfetti && newValue) {
      // Semi-time the end of the bar fill animation.
      setTimeout(() => {
        const self = confParentRef.current;
        const topPos = self
          ? Math.round(self.getBoundingClientRect().top + self.clientWidth / 2)
          : 0;
        const leftPos = self
          ? Math.round(
              self.getBoundingClientRect().left + self.clientHeight / 2
            )
          : 0;
        setCheckedPos([topPos, leftPos]);
        setState((prevState) => ({
          ...prevState,
          runConfetti: true,
        }));
        Haptics.impact({
          style: ImpactStyle.Heavy,
        }).catch((err) => console.log("HapticsErr", err));
      }, FILL_DELAY_MS); // Match with sound delay and fillBar animation.

      Haptics.impact({
        style: ImpactStyle.Medium,
      }).catch((err) => console.log("HapticsErr", err));
    } else {
      setState((prevState) => ({ ...prevState, runConfetti: false }));
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClickAlarm = () => {
    setOpenAlarmModal(true);
  };
  const handleCloseAlarmModal = () => {
    setOpenAlarmModal(false);
  };
  const handleEnterBlur = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      (document.activeElement as HTMLElement).blur();
    }
  };
  const handleOpenDeleteDialog = () => {
    const [head, ...tail] = stability;
    if (tail && tail.includes(true)) {
      setOpenDeleteDialog(true);
      return;
    }

    (document.activeElement as HTMLElement).blur();
    handleDelete(id);
    return;
  };

  if (hidden) {
    // Hack to reset confetti.
    if (state.runConfetti) {
      setState((prevState) => ({ ...prevState, runConfetti: false }));
    }
    return null;
  }
  const maybeConfetti = state.runConfetti ? (
    <MemoizedConfetti
      topPos={checkedPos[0]}
      leftPos={checkedPos[1]}
    ></MemoizedConfetti>
  ) : null;

  const alarmButton = localAlarm ? (
    <IconButton
      key={"alarm"}
      style={{
        color: "lightgray",
      }}
      onClick={handleClickAlarm}
      onMouseDown={(e) => {
        // Don't leave edit mode on habit.
        e.preventDefault();
      }}
      sx={{
        width: SQUARE_SIZE,
        height: SQUARE_SIZE,
      }}
      size="small"
    >
      <AlarmOnIcon />
    </IconButton>
  ) : (
    <IconButton
      key={"alarm"}
      onClick={handleClickAlarm}
      onMouseDown={(e) => {
        // Don't leave edit mode on habit.
        e.preventDefault();
      }}
      sx={{
        width: SQUARE_SIZE,
        height: SQUARE_SIZE,
      }}
      size="small"
    >
      <AddAlarmIcon />
    </IconButton>
  );

  const saveNameChanges = () => {
    setState((prevState) => ({ ...prevState, isEditing: false }));
    handleEditing(false);
    if (localName !== name) {
      handleChangeName(id, localName);
    }
  };

  const editingActions = (
    <Box display="flex" mr={"0.5rem"} ml={"0.5rem"}>
      <IconButton
        // just a dummy that causes deblur, which kicks off the save.
        // onClick={() => {
        //   saveNameChanges();
        // }}
        // onMouseDown={(e) => {
        //   // Don't leave edit mode on habit.
        //   e.preventDefault();
        // }}
        key={"done"}
        sx={{
          width: SQUARE_SIZE,
          height: SQUARE_SIZE,
          color: "bluegreen",
        }}
        size="small"
      >
        <DoneIcon />
      </IconButton>
      {alarmButton}
      <IconButton
        key={"delete"}
        style={{
          color: "red",
        }}
        sx={{
          width: SQUARE_SIZE,
          height: SQUARE_SIZE,
        }}
        onClick={(e) => {
          handleOpenDeleteDialog();
        }}
        onMouseDown={(e) => {
          console.log("clicked can start");
          // Don't leave edit mode on habit.
          e.preventDefault();
        }}
        size="small"
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
  // const isInteractable =
  //   depth > 0 || // Leaf habits.
  //   numChildren === 0 || // Orphan root triggers are habits.
  //   (numChildren > 1 && isCollapsed) || // Collapsed root triggers can be toggled to mark the whole routine.
  //   state.hasFocus; // Currently editing the habit.
  // // Weird to track the trigger seperately from the habit when there's only one.
  // const action = isInteractable ? validAction : null;

  const alarmProps = {
    localAlarm,
    saveAlarm: (newAlarm: LocalAlarm) => {
      handleChangeAlarm(id, newAlarm);
    },
    openAlarmModal,
    handleCloseAlarmModal,
    name,
    id: id.toString(),
  };

  const getDisplayStability = (level, selfDepth) => {
    if (!level || level.depth === 0 || selfDepth === 0) {
      return null;
    }
    return level?.stability?.slice(0, historyDatesToShow) || [];
  };

  const myIndex = findIndex(sortlyLevels, id);
  const stabilityAbove = getDisplayStability(sortlyLevels[myIndex - 1], depth);
  const stabilityBelow = getDisplayStability(sortlyLevels[myIndex + 1], depth);

  const deleteDialog = (
    <div>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          <Typography>
            {`🎉 Great job learning that this habit doesn't work for you right now!`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={() => handleDelete(id)} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  // Even if we hide the entries, we don't want the text spilling over to the tracker area.
  const itemStreak = (
    <ItemStreak
      confettiRef={confParentRef}
      nowLocalDateStr={nowLocalDateStr}
      stability={stability.slice(0, historyDatesToShow)}
      id={id.toString()}
      onTap={(e) => {
        const value = stability[0];
        // Cycle through miss, success, 'big success', and skip.
        handleChangeChecked(!value);
      }}
      stabilityAbove={stabilityAbove}
      stabilityBelow={stabilityBelow}
    />
  );
  const visibility = hideEntries ? "hidden" : "visible";
  const entriesUI = (
    <Box sx={{ visibility }} mt={lowerEntries ? 3 : 0}>
      {itemStreak}
    </Box>
  );

  const core = state.isEditing ? editingActions : entriesUI;
  const defaultPlaceholder =
    depth === 0 ? "When will you start?" : "New habit (as a tiny action)";
  const fontSize = depth === 1 ? "1rem" : "1.1rem";
  const fontFamily = depth === 1 ? null : SERIF_FAMILY;
  const fontWeight = depth === 1 ? "400" : "700";
  const regularInputSize = getApproximateLengthWidthAdjusted(
    localName || defaultPlaceholder
  );
  const inputSize = depth === 1 ? regularInputSize : regularInputSize;
  return (
    <Box
      key={id}
      style={{
        // ML LINEUP.
        marginLeft: depth === 0 ? "-0.5rem" : 0, // Account for
        fontSize: "smaller",
        flexGrow: 1, // Instead of width: "100%" since the accordian summary is probably a flexbox.
        height: SQUARE_SIZE,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {deleteDialog}
      <AlarmModal {...alarmProps} />
      <Box
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        display="flex"
      >
        <Box width={"fit-content"} flexGrow={1}>
          {/* container to limit length of the strikethrough */}
          <InputBase
            value={localName}
            onChange={(e) => {
              // Update local version of habit name
              setLocalName((prevState) => {
                return e.target.value;
              });
            }}
            onKeyDown={handleEnterBlur}
            onBlur={() => {
              saveNameChanges();
            }}
            onFocus={(e) => {
              setState((prevState) => ({ ...prevState, isEditing: true }));
              handleEditing(true);
            }}
            autoFocus={isNew}
            placeholder={defaultPlaceholder}
            className={
              stability[0] && !state.isEditing && !showAddHabitButtons
                ? "strike"
                : ""
            }
            style={
              state.isEditing
                ? {
                    marginRight: "0.5rem",
                    backgroundColor: "white",
                    borderRadius: "0.3rem",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    border: "1px solid lightgrey",
                    width: "100%",
                    fontSize,
                    fontFamily,
                    fontWeight,
                  }
                : {
                    marginRight: "0.5rem",
                    border: "1px dotted transparent",
                    color:
                      stability[0] && !showAddHabitButtons ? "#999" : "black",
                    fontSize,
                    fontFamily,
                    fontWeight,
                  }
            }
            inputProps={{
              style: state.isEditing ? {} : { textOverflow: "ellipsis" },
              size: inputSize,
            }}
          />
        </Box>

        {core}
        {maybeConfetti}
      </Box>
    </Box>
  );
};

export default HabitRow;

import ConfettiExplosion from "./css-confetti";
const POSSIBLE_COLORS = ["#ffbe4f", "#6bd2db", "#0ea7b5", "#0c457d", "#e8702a"];

export default function LeftConfetti({ topPos, leftPos }) {
  const canvasHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  const confHeight = Math.min(400, canvasHeight - topPos);
  const color1i = Math.floor(Math.random() * POSSIBLE_COLORS.length);
  const color2i = Math.floor(Math.random() * POSSIBLE_COLORS.length);
  const bigOne = Math.random() > 0.7;
  return (
    <ConfettiExplosion
      style={{
        position: "fixed",
        top: topPos,
        left: leftPos,
      }}
      // width={canvasWidth}
      // height={canvasHeight}
      width={Math.random() * 100 + 550}
      height={confHeight}
      force={Math.random() * 0.1 + 0.7}
      duration={Math.random() * 500 + 1800}
      particleSize={Math.random() * 4 + 7}
      colors={[POSSIBLE_COLORS[color1i], POSSIBLE_COLORS[color2i]]}
      particleCount={Math.random() * 7 + (bigOne ? 15 : 2)}
      // drawShape={(ctx) => {
      //   ctx.beginPath();
      //   ctx.arc(0, 0, RAD_MIN + RAD_DELTA * Math.random(), 0, 2 * Math.PI);
      //   ctx.fill();
      // }}
      // initialVelocityX={{ min: -3, max: -1 }}
      // initialVelocityY={7}
      // tweenDuration={1}
      // gravity={0.4}
    ></ConfettiExplosion>
  );
}

import { useChat } from "ai/react";
import { useCallback, useEffect, useState } from "react";
import { insertDataIntoMessages } from "./transform";
import { ChatInput, ChatMessages } from "./chat";
import { Message as LibMessage } from "ai";
import store from "store2";

const MIN_REPLY_DELAY = 600;
const MAX_REPLY_DELAY = 900;

const MIN_BASE_PAUSE = 1700;
const MAX_BASE_PAUSE = 3500;
const PAUSE_PER_CHAR = 70;

const IS_PROD = true;

const BACKEND_ENDPOINT = IS_PROD
  ? "https://songjiapei.pythonanywhere.com/q3rga34gq3hb0424fksdf"
  : "http://127.0.0.1:5000/q3rga34gq3hb0424fksdf";
const TOPNOTES_ENDPOINT = IS_PROD
  ? "https://songjiapei.pythonanywhere.com/gq394gjf98vqb293q2f"
  : "http://127.0.0.1:5000/gq394gjf98vqb293q2f";
const PROGRESS_ENDPOINT = IS_PROD
  ? "https://songjiapei.pythonanywhere.com/einwofigemaWFYARDWYUN"
  : "http://127.0.0.1:5000/einwofigemaWFYARDWYUN";

const CONVO_CACHE_KEY = "convo_cache";
const CONVO_DATE_CACHE_KEY = "convo_time_cache";
let convo_cache = store(CONVO_CACHE_KEY) || [];
const cache_date_text = store(CONVO_DATE_CACHE_KEY) || null;

const TOP_NOTES_CACHE_KEY = "top_notes_cache";
let topNotes = store(TOP_NOTES_CACHE_KEY) || "";

const MAX_CONVOS_TO_CACHE = 10;
const MAX_CONVOS_VISIBLE = 5;

function compressedLevel(l: any) {
  return {
    name: l.name,
    stability: (l.stability || []).map((completion) => (completion ? 1 : 0)),
    localAlarm: l.localAlarm,
    created: (l.id || "").split("_")[2],
    isRoutine: l.depth === 0,
    isHabit: l.depth === 1,
  };
}

function toMessageLog(m) {
  return {
    role: m.role === "user" ? "client" : "coach",
    content: m.content,
    createdAt: m.createdAt,
  };
}

const GET_FEEDBACK_ANYWAYS_CHANCE = 0.2;
const CANNED = {
  perfect: [
    "🎉 Great job! You're crushing it!",
    "🌟 Way to go! Consistency pays off.",
    "🙌 High five! Keep up the awesome work.",
    "💪 You're on fire! Keep those habits going.",
    "🎊 Celebrate your progress — it's worth it!",
    "🌈 You're building something amazing!",
    "🎈 Cheers to another successful day!",
    "🌟 You're making waves! Keep it up.",
    "🎯 Nailed it! Keep that momentum.",
    "🌻 Your dedication is inspiring!",
    "🎖️ Gold star for you! Keep shining.",
    "🎇 Keep going—you're doing fantastic!",
    "🎁 Treat yourself — you've earned it.",
    "🌺 Your consistency will pay off!",
    "🌼 Persistence pays off!",
    "🎊 You're unstoppable!",
    "🌟 Keep that positive momentum.",
    "🎖️ You're making progress!",
    "🎇 Way to stick with it!",
    "🌈 Consistency is key!",
    "🎈 You're on the right track.",
    "🌟 Keep those habits strong!",
    "🎯 You're building something great.",
    "🎁 Celebrate your commitment.",
    "🌻 Perfect day!",
  ],
  diligent: [],
};
async function getProgressContent(
  type: string,
  sortlyLevels: any,
  sortlyId: any,
  identity: any,
  messages: any,
  currentTopOfMind: any
) {
  return fetch(PROGRESS_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type,
      habits: sortlyLevels.map(compressedLevel),
      identity,
      completed: sortlyLevels.find((l) => l.id === sortlyId)["name"],
      time: new Date().toLocaleString(),
      messages,
      currentTopOfMind,
    }),
  }).then((res) => {
    if (!res.ok) {
      console.log("network error getting progress", res);
      return null;
    }
    return res.text();
  });
}

export default function ChatSection({
  sortlyLevels = [],
  identityStr,
  dateStr,
  pendingAction,
  clearPendingAction,
}) {
  const strippedLevels = sortlyLevels.map(compressedLevel);
  const habitsHistoryStr = JSON.stringify(strippedLevels);
  const chatExtras = {
    habits: habitsHistoryStr,
    identity: identityStr,
    time: new Date().toLocaleString(),
    topNotes,
  };
  // Just replace this with my own api calls.
  const {
    messages,
    input,
    setInput,
    isLoading,
    handleSubmit,
    handleInputChange,
    reload,
    stop,
    data,
    setMessages,
  } = useChat({
    api: BACKEND_ENDPOINT,
    headers: {
      "Content-Type": "application/json", // using JSON because of vercel/ai 2.2.26
    },
    body: {
      extras: chatExtras,
    },
  });
  const [pendingStatus, setPendingStatus] = useState({
    messages: [],
    lastDisplayed: performance.now(),
  });
  const [display, setDisplay] = useState([]);
  const [processed, setProcessed] = useState(
    new Set(convo_cache.map((m) => m.id))
  );
  const [inReplyDelay, setInReplyDelay] = useState(true);
  const addProcessed = (item: String) => {
    setProcessed((prev) => new Set(prev).add(item));
  };
  const [extraLoading, setExtraLoading] = useState(false);
  const [lastProgress, setLastProgress] = useState(null);
  const anyLoading = isLoading || extraLoading;

  // Restore message history if none, and cache exists.
  useEffect(() => {
    if (messages.length === 0 && convo_cache.length > 0) {
      console.log("restoring chat");
      // Only restore the most recent messages, and mark as processed so they don't get re-displayed.
      // setProcessed(new Set(convo_cache.map((m) => m.id)));
      setMessages(convo_cache);

      // only restore to visible notes if it's the same day and within visibility counter.
      const mostRecent = convo_cache.slice(
        Math.max(0, convo_cache.length - MAX_CONVOS_VISIBLE)
      );
      if (cache_date_text && cache_date_text === dateStr) {
        setDisplay(mostRecent);
      }
    }
  }, [dateStr, messages, setMessages, setProcessed]);

  const setPending = (messages) => {
    setPendingStatus((prevPendingStatus) => {
      return { ...prevPendingStatus, messages };
    });
  };
  const addDisplayIdem = useCallback(
    (item) => {
      setDisplay((prev) => {
        if (prev.map((m) => m.id).includes(item.id)) {
          return prev;
        }
        // populate cache as we go.
        setTimeout(() => {
          if (convo_cache.map((m) => m.id).includes(item.id)) {
            return;
          }
          convo_cache.push({
            ...item,
          });
          // If we surpass the cache limit, we batch into an update notes request.
          if (convo_cache.length > MAX_CONVOS_TO_CACHE) {
            // Split messages into most recent 10 vs all older.
            const cutoff = Math.max(0, convo_cache.length - MAX_CONVOS_VISIBLE);
            const toSend = convo_cache.slice(0, cutoff);
            const toKeep = convo_cache.slice(cutoff);
            const logsToSend = toSend.map(toMessageLog);

            const topnotesBody = {
              habits: habitsHistoryStr,
              identity: identityStr,
              currentTopOfMind: topNotes,
              messages: logsToSend,
            };

            console.log("summarize", topnotesBody);
            // Send post to end point to get new top notes.
            fetch(TOPNOTES_ENDPOINT, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(topnotesBody),
            })
              .then((res) => {
                if (!res.ok) {
                  console.log("network error getting topnotes", res);
                  return null;
                }
                return res.text();
              })
              .then((data) => {
                if (data) {
                  console.log("got new top notes", data);
                  topNotes = data;
                  store(TOP_NOTES_CACHE_KEY, topNotes);

                  convo_cache = toKeep;
                  store(CONVO_CACHE_KEY, convo_cache);
                }
              });
          } else {
            // Otherwise, just update the cache.
            console.log("adding to cache", convo_cache);
            store(CONVO_CACHE_KEY, convo_cache);
          }
          // mark the cache as up to date.
          if (!cache_date_text || cache_date_text !== dateStr) {
            store(CONVO_DATE_CACHE_KEY, dateStr);
          }
        }, 0);
        // populate display up to the most allowed visible.
        const updated = [...prev, item];
        return updated.slice(Math.max(0, updated.length - MAX_CONVOS_VISIBLE));
      });
    },
    [setDisplay, habitsHistoryStr, identityStr, dateStr]
  );
  const addPendingIdem = (item) => {
    setPendingStatus((prevPendingStatus) => {
      if (item.id in prevPendingStatus.messages.map((m) => m.id)) {
        return prevPendingStatus;
      }
      return {
        ...prevPendingStatus,
        messages: [...prevPendingStatus.messages, item],
      };
    });
  };

  // Add messages based on any pending actions.
  useEffect(() => {
    if (pendingAction) {
      const fetchEncouragement = async () => {
        setExtraLoading(true);
        const content = await getProgressContent(
          pendingAction[0],
          sortlyLevels,
          pendingAction[1],
          identityStr,
          messages.map(toMessageLog),
          topNotes
        );
        if (content) {
          const message = {
            id: `encourage-${new Date().getTime()}`,
            role: "assistant" as const,
            content,
          };
          console.log("Got progress", message);
          // Setting should kick off the pending message loop.
          setMessages([...messages, message]);
        }
        setLastProgress(new Date().getTime());
        setExtraLoading(false);
      };

      // Can give feedback if last message was not from assistant, or if it's been 100 seconds since last feedback.
      if (
        messages[messages.length - 1]?.role !== "assistant" ||
        lastProgress === null ||
        new Date().getTime() - lastProgress > 100 * 1000 ||
        Math.random() < GET_FEEDBACK_ANYWAYS_CHANCE
      ) {
        fetchEncouragement();
      }
      clearPendingAction();
    }
  }, [
    pendingAction,
    messages,
    setMessages,
    clearPendingAction,
    sortlyLevels,
    identityStr,
    addDisplayIdem,
    lastProgress,
  ]);

  const withData = insertDataIntoMessages(messages, data);
  // Skim any messages after the first in response, add to pending messages queue for subsequent message looper (run every second with randomization)
  withData.forEach((messageObj, i) => {
    if (processed.has(messageObj.id)) {
      return;
    }
    console.log("Processing", messageObj);
    addProcessed(messageObj.id);
    const text = messageObj.content;
    if (messageObj.role === "user") {
      addDisplayIdem(messageObj);
      return;
    }
    // Only message - append to display directly
    if (text.indexOf("\n") === -1) {
      addPendingIdem(messageObj);
      return;
    }
    // Split messages by every occurrence '\n'
    const splitPieces = text.split("\n").filter((piece) => piece);
    console.log("Got", splitPieces);
    // insert <num> as pauseNum in the message data field
    let pendingPauseNum = null;
    for (let j = 0; j < splitPieces.length; j++) {
      const subStr = splitPieces[j];
      if (subStr.startsWith("\n")) {
        pendingPauseNum =
          Math.random() * (MAX_BASE_PAUSE - MIN_BASE_PAUSE) + MIN_BASE_PAUSE;
        continue;
      }
      if (pendingPauseNum !== null) {
        // This is coming after a pause - add to pending messages
        const totalWaitTime = pendingPauseNum + subStr.length * PAUSE_PER_CHAR;

        addPendingIdem({
          ...messageObj,
          id: `${messageObj.id}-${i}-${j}`,
          content: subStr,
          data: { pauseNum: totalWaitTime },
        });
        pendingPauseNum = null;
        continue;
      }
      // First message - wait to give dots after reading delay.
      if (!subStr.length) {
        console.log("first message", messageObj);
      }
      const basePause =
        Math.random() * (MAX_BASE_PAUSE - MIN_BASE_PAUSE) +
        MIN_BASE_PAUSE +
        MIN_REPLY_DELAY;
      const totalWaitTime = basePause + (subStr?.length || 0) * PAUSE_PER_CHAR;

      addPendingIdem({
        ...messageObj,
        id: `${messageObj.id}-${i}-${j}`,
        content: subStr,
        data: { pauseNum: totalWaitTime },
      });
      continue;
    }
  });

  // Timer to loop through pending messages and add in order, updating lastDisplayed
  useEffect(() => {
    if (pendingStatus && pendingStatus.messages?.length > 0) {
      const timeoutId = setTimeout(() => {
        // Set state is being double run by react in strict mode? NEEDS TO BE IDEMPOTENT.
        setPendingStatus((prevPendingStatus) => {
          if (prevPendingStatus.messages?.length === 0) {
            return prevPendingStatus;
          }
          const now = performance.now();
          const timeSinceLast = now - prevPendingStatus.lastDisplayed;
          const [nextMessage, ...rest] = prevPendingStatus.messages;
          const waitTime = nextMessage?.data?.pauseNum;
          if (timeSinceLast < waitTime) {
            return { ...prevPendingStatus, messages: [nextMessage, ...rest] }; // Return new object to re-trigger effect and timer.
          }
          addDisplayIdem(nextMessage);
          return { ...prevPendingStatus, messages: rest };
        });
      }, Math.random() * 400 + 800);
      console.log("setup timeout", timeoutId, performance.now());
    }
  }, [addDisplayIdem, pendingStatus]);

  // clear pending messages if user hits enter or starts typing with randomized chance after delay
  const clearPending = () => {
    const cutoffMessage = display[display.length - 1];
    if (cutoffMessage && pendingStatus.messages?.length > 0) {
      const truncatedMessages = [];
      // console.log("Cutoff message", cutoffMessage, messages);
      for (let i = 0; i < messages.length; i++) {
        const m = messages[i];
        const foundIndex = m.content.indexOf(cutoffMessage.content);
        // User is continuing to type right after themselves.
        if (foundIndex !== -1 && m.role === "user") {
          // console.log("User is continuing to type right after themselves");
          truncatedMessages.push(m);
          // The rest of messages will never be added to pending / shown.
          break;
        } else if (foundIndex !== -1) {
          const lastIndex = foundIndex + cutoffMessage.content.length;
          const seenSoFar = m.content.slice(0, lastIndex);
          // Only what has already been displayed should be kept in memory.
          truncatedMessages.push({
            ...m,
            content: seenSoFar,
          });
          // console.log("Truncating at", seenSoFar, truncatedMessages);
          // The rest of messages will never be added to pending / shown.
          break;
        } else {
          truncatedMessages.push(m);
        }
      }
      setPending([]);
      setMessages(truncatedMessages);
      // console.log("Truncated messages", truncatedMessages, pending);
    }
  };

  // If receive WAIT anywhere in message - stop animation and wait that long for user to start typing before re-submitting?

  // STRETCH: randomize ai continuing conversation after a certain amount of waiting? depending on context of conversation? (checking in, pushing more, switching topic a little, etc.)
  // STRETCH: use local quick model to judge if user is going to keep typing ?

  // PENDING CAN BE CANCELED IF USER STARTED TYPING switched everything to go through pending anyways (|| display[display.length - 1]?.role === "user")
  const showPendingAnimation =
    (pendingStatus.messages?.length > 0 || anyLoading) && !inReplyDelay;
  return (
    <div className="space-y-4 max-w-5xl w-full mb-16">
      <ChatMessages
        messages={display}
        isLoading={anyLoading}
        reload={reload}
        stop={stop}
        hasPending={showPendingAnimation}
      />
      <ChatInput
        input={input}
        handleSubmit={(e) => {
          const typeDelay =
            Math.random() * (MAX_REPLY_DELAY - MIN_REPLY_DELAY) +
            MIN_REPLY_DELAY;
          setInReplyDelay(true);
          setTimeout(() => {
            setInReplyDelay(false);
          }, typeDelay);
          if (pendingStatus.messages?.length > 0) {
            clearPending();
          }
          handleSubmit(e);
          // STRETCH: optimistically show user message right away, put chance clearPending on timer etc.
        }}
        handleNote={(e) => {
          const noteMessage: LibMessage = {
            id: `note-${new Date().getTime()}`,
            role: "user" as const,
            content: input,
          };
          setMessages([...messages, noteMessage]);
          addDisplayIdem(noteMessage);
          setInput("");
        }}
        handleInputChange={(e) => {
          if (pendingStatus.messages?.length > 0) {
            const pendingLength =
              pendingStatus.messages[0]?.content?.length || 0;
            if (pendingLength > 2 && Math.random() < 0.5) {
              clearPending();
            }
            if (pendingLength > 8 && Math.random() < 0.8) {
              clearPending();
            }
          }
          // STRETCH: same as above, with lower probability.
          handleInputChange(e);
        }}
        isLoading={anyLoading}
      />
    </div>
  );
}

import React from "react"
import { createRoot } from 'react-dom/client';
import "./index.css"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import * as Sentry from "@sentry/capacitor";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { FB_CONFIG } from "./util"
import { Capacitor } from "@capacitor/core"

console.log('Index.js loaded', performance.now());

try {
  Sentry.init({
    dsn:
      "https://dc375748a63a4a28b0754cf9c12da332@o506351.ingest.sentry.io/5595939",
    autoSessionTracking: true,
    environment: process.env.NODE_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
} catch (e) {
  console.error('Sentry init issue', e);
}

const container = document.getElementById("root")
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// How to initialize web offline without web.ts:298 Uncaught (in promise)
if (Capacitor.getPlatform() === "web") {
  try {
    FirebaseAnalytics.initializeFirebase(FB_CONFIG).then(() => {
      return FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
      }).catch((e) => {
        console.log('Firebase web error 1', e);
        return;
      });
    }).catch((e) => {
      console.log('Firebase web error 2', e);
      return;
    });
  } catch (e) {
    console.log('Firebase web error CATCH', e);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// TODO put this into a UI component that lets user control updates.
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.waiting.postMessage({ type: "SKIP_WAITING" })
    window.location.reload()
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// qreportWebVitals(console.log)

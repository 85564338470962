import { Box, Tooltip, Typography } from "@mui/material";
import { MASCOT_TYPES, SERIF_FAMILY } from "../../util";
import seedrandom from "seedrandom";

export default function MascotHeader({ mascotType, mascotName, dateStr }) {
  const isDefaultMascot = mascotType === null;
  const mascotFace = !isDefaultMascot ? MASCOT_TYPES[mascotType - 1] : "🦉";
  const talk = !isDefaultMascot
    ? mascotType === 1
      ? "Woof!"
      : "Meow!"
    : "Unlock a unique buddy when you get more consistent!";
  const delayTime = mascotType !== null ? 1000 : 3000;
  const rngHue = seedrandom(dateStr + "hue");
  const hue = rngHue.quick() * 360;
  const rngSaturation = seedrandom(dateStr + "saturation");
  const saturation = rngSaturation.quick() * 40;
  const rngLightness = seedrandom(dateStr + "lightness");
  const lightness = rngLightness.quick() * 10 + 85;
  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"start"}
      px={1.5}
      bgcolor={color}
      mx={1}
      // py={0.5}
      mt={4}
      borderRadius={8}
    >
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={delayTime}
        placement="top"
        title={talk}
        arrow
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        }}
      >
        <Typography
          sx={{ fontSize: "2rem", textShadow: "1px 1px 3px lightgray" }}
        >
          {mascotFace}
        </Typography>
      </Tooltip>
      {/* <Box
        overflow={"visible"}
        borderRadius={"100%"}
        width={"1.7rem"}
        height={"0.5rem"}
        marginTop={"-1rem"}
        boxShadow={"0 0 3px 1px rgba(160, 160, 160, 0.9)"}
        sx={{ backgroundColor: "rgba(160, 160, 160, 1)", top: "0.3rem" }}
        zIndex={-1}
      ></Box> */}
      {mascotName && (
        <Typography
          sx={{ opacity: 0.8 }}
          ml={1}
          fontFamily={SERIF_FAMILY}
          fontWeight={600}
        >
          {mascotName}
        </Typography>
      )}
    </Box>
  );
}

import { Box, Button, Container, Typography } from "@mui/material";
import { Wizard, useWizard } from "react-use-wizard";

import {
  BASE_GOALS,
  COLOR_INDEX_BACK_RGB,
  getGoalText,
  isInStandaloneMode,
} from "../../util";

import { lazy, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

const ExampleChart = lazy(() => import("./pieces/example-chart"));

const getGoalEmoji = (goal) => {
  switch (goal) {
    case BASE_GOALS[0]:
      return "🏃";
    case BASE_GOALS[1]:
      return "🧘";
    case BASE_GOALS[2]:
      return "🧑‍🎓";
  }
};
const GoalButton = ({ onSetGoal, goal }) => {
  const goalText = getGoalText(goal);
  const goalEmoji = getGoalEmoji(goal);
  return (
    <Box mt={2} textAlign={"center"}>
      <Button
        variant="outlined"
        sx={{
          textTransform: "none",
          color: "black",
          borderColor: "black",
          width: "80%",
        }}
        onClick={() => {
          FirebaseAnalytics.logEvent({
            name: "Goal",
            params: {
              category: "StartModal",
              content: goal,
            },
          });
          onSetGoal(goal);
        }}
      >
        <Typography variant="h5" mr={1}>
          {goalEmoji}
        </Typography>
        <Typography fontSize={"1.4em"}>{goalText}</Typography>
      </Button>
    </Box>
  );
};

const graphicSize = "20rem";

const backButtonStyle = {
  position: "fixed",
  left: 4,
  top: "calc(env(safe-area-inset-top) + 6px)",
  textTransform: "none",
};

const MIN_STARTER_HABIT_LENGTH = 3;
const MIN_ACTUAL_HABIT_LENGTH = 6;

const SetReason = ({ starterHabit, onSetGoal }) => {
  const { nextStep, previousStep } = useWizard();
  const isNative = Capacitor.isNativePlatform() || isInStandaloneMode();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        width: "80%",
        height: "90%",
        margin: "auto",
        maxWidth: 600,
        justifyContent: "space-around",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-around"}
        height={"100%"}
      >
        <div
          style={{
            height: graphicSize,
            width: graphicSize,
            transform: "rotate(-40deg)",
            position: "fixed",
            left: "-5em",
            top: "-5em",
            zIndex: -1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            // From util.ts.
            backgroundImage: `linear-gradient(to bottom, rgba(${COLOR_INDEX_BACK_RGB}, 0) 40%, rgba(${COLOR_INDEX_BACK_RGB})), url('/sources.webp')`,
          }}
        />

        <Box
          width={"fit-content"}
          ml={"2em"}
          mr={"auto"}
          textAlign={"left"}
          // From util.ts.
          style={{
            marginTop: "18vh",
            // background: "rgba(246,249,255, 0.8)",
          }}
        >
          <Typography fontSize={"1.8em"} mt={1}>
            <strong className="highlight">Meaningful</strong> and
          </Typography>
          <Typography fontSize={"1.8em"}>
            <strong className="highlight">flexible</strong> habit-building in 2
            minutes a day.
          </Typography>
        </Box>
        <Box mt={2} textAlign={"center"}>
          <Typography fontSize={"1.4em"}>I want to focus on being:</Typography>
          {BASE_GOALS.map((goal) => (
            <GoalButton
              goal={goal}
              key={goal}
              onSetGoal={() => {
                onSetGoal(goal);
                nextStep();
              }}
            ></GoalButton>
          ))}
        </Box>
        {!isNative && (
          <a
            href="https://apps.apple.com/us/app/diligent-daily-habits/id6449609066?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              width: "250px",
              height: "83px",
              transform: "scale(0.6)",
            }}
            onClick={() => {
              FirebaseAnalytics.logEvent({
                name: "AppStore",
                params: {
                  category: "StartModal",
                },
              });
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1704412800"
              alt="Download on the App Store"
              style={{ borderRadius: "13px", width: "250px", height: "83px" }}
            />
          </a>
        )}
      </Box>

      {/* <Button sx={backButtonStyle} onClick={previousStep}>
        back
      </Button> */}
    </Box>
  );
};

const getImprovementTerm = (goal) => {
  switch (goal) {
    case "healthy":
      return "Get fit.";
    case "happy":
      return "Feel good.";
    default: // productive
      return "Get stuff done.";
  }
};

const getRecommendedHabits = (goal) => {
  switch (goal) {
    case "healthy":
      return [
        "Drink water: hydrate for more energy",
        "Do 2 pushups: build strength daily",
        "Do 1 stretch: maintain mobility and sleep better",
      ];
    case "happy":
      return [
        "Deep breaths outside: fresh air to brighten your mood",
        "Message a friend: stay connected and be supportive",
        "Write in journal: close out your day with gratitude",
      ];
    default: // productive
      return [
        "Fold my blankets: start the day with an accomplishment",
        "Clean up 1 thing: maintain your environment",
        "Read 2 pages: learn something every day",
      ];
  }
};

export const getTriggerForStarterHabit = (starterHabit) => {
  switch (starterHabit) {
    case getRecommendedHabits("healthy")[0]:
      return "When I wake up";
    case getRecommendedHabits("healthy")[1]:
      return "After work";
    case getRecommendedHabits("healthy")[2]:
      return "Before bed";
    case getRecommendedHabits("happy")[0]:
      return "When I wake up";
    case getRecommendedHabits("happy")[1]:
      return "When I eat lunch";
    case getRecommendedHabits("happy")[2]:
      return "Before bed";
    case getRecommendedHabits("productive")[0]:
      return "After I wake up";
    case getRecommendedHabits("productive")[1]:
      return "After I finish work";
    case getRecommendedHabits("productive")[2]:
      return "Before bed";
  }
};

const StarterHabits = ({ goal, onSelected }) => {
  const getHabitButton = (habit) => {
    const displayChunks = habit.split(":");
    return (
      <Box mr="auto" ml="auto">
        <Button
          variant="outlined"
          size="large"
          key={habit}
          fullWidth
          sx={{
            margin: "auto",
            marginTop: "1rem",
            textTransform: "none",
            color: "black",
            fontSize: "1.2em",
            borderColor: "black",
          }}
          onClick={() => {
            FirebaseAnalytics.logEvent({
              name: "Pick",
              params: {
                category: "StartModal",
                content: habit,
              },
            });
            onSelected(habit);
          }}
        >
          <strong>{displayChunks[0]}</strong>
        </Button>
        <Typography textAlign={"center"}>{displayChunks[1]}</Typography>
      </Box>
    );
  };

  switch (goal) {
    case "healthy":
      return (
        <Box>
          {getRecommendedHabits(goal).map((habit) => getHabitButton(habit))}
        </Box>
      );
    case "happy":
      return (
        <Box>
          {getRecommendedHabits(goal).map((habit) => getHabitButton(habit))}
        </Box>
      );
    default: // productive
      return (
        <Box>
          {getRecommendedHabits(goal).map((habit) => getHabitButton(habit))}
        </Box>
      );
  }
};

const ImproveHabit = ({
  starterHabit,
  setStarterHabit,
  goal,
  onSetFirstHabit,
}) => {
  const { nextStep, previousStep } = useWizard();
  const [actualHabit, setActualHabit] = useState("");

  const hasStarterHabit =
    starterHabit && starterHabit.length > MIN_STARTER_HABIT_LENGTH;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        width: "80%",
        height: "99vh",
        margin: "auto",
        maxWidth: 600,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-around"}
        height={"100%"}
      >
        <Box mt={"15%"} textAlign={"center"}>
          <ExampleChart />
          <Typography fontSize={"1.6em"} mt={1}>
            <strong className="highlight">Build momentum</strong> by starting
            with a small and consistent daily habit.
          </Typography>
        </Box>

        <Box textAlign={"left"} width={"100%"}>
          <Box mt={2}>
            <Box>
              <StarterHabits
                goal={goal}
                onSelected={(habit) => {
                  onSetFirstHabit(habit);
                }}
              ></StarterHabits>
            </Box>
          </Box>
        </Box>
      </Box>

      <Button sx={backButtonStyle} onClick={previousStep}>
        back
      </Button>
    </Box>
  );
};

function StartModal({ onSetGoal, goal, onSetFirstHabit }) {
  useEffect(() => {
    if (isInStandaloneMode()) {
      // ReactGA.event({
      //   category: "StartModal",
      //   action: "pwa-installed",
      // });
    }
  }, []);
  const [starterHabit, setStarterHabit] = useState("");

  return (
    <Container sx={{ height: "98vh" }}>
      <Wizard>
        <SetReason starterHabit={starterHabit} onSetGoal={onSetGoal} />
        <ImproveHabit
          starterHabit={starterHabit}
          setStarterHabit={setStarterHabit}
          goal={goal}
          onSetFirstHabit={onSetFirstHabit}
        />
      </Wizard>
    </Container>
  );
}

StartModal.POPUP_KEY = "StartModal";
StartModal.LAST_SEEN_KEY = "StartModal";

export default StartModal;

import { useRef } from "react";

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export interface ChatInputProps {
  /** The current value of the input */
  input?: string;
  /** An input/textarea-ready onChange handler to control the value of the input */
  handleInputChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  /** Form submission handler to automatically reset input and append a user message  */
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleNote: (e) => void;
  isLoading: boolean;
}

export default function ChatInput(props: ChatInputProps) {
  // Use a ref to re-focus the input after a message is sent.
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <>
      <form
        ref={formRef}
        onSubmit={(e) => {
          props.handleSubmit(e);
          inputRef.current?.focus();
          FirebaseAnalytics.logEvent({
            name: "SendMessage",
            params: {
              category: "Chat",
            },
          });
        }}
        className="flex flex-row justify-between items-stretch w-full max-w-5xl p-2 rounded-xl gap-2"
      >
        <textarea
          ref={inputRef}
          autoFocus
          name="message"
          placeholder=""
          className="w-full p-2 rounded-xl shadow-inner flex-1 border-1 outline outline-gray-300 min-h-20"
          autoComplete="off"
          value={props.input}
          onChange={props.handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              formRef.current?.requestSubmit();
            }
          }}
        />
        <div className="flex flex-col gap-1 justify-between">
          <button
            type="button"
            className="px-2 py-1 border-sky-600 border rounded-xl disabled:opacity-50 disabled:cursor-not-allowed text-sky-600 grow"
            onClick={(e) => {
              FirebaseAnalytics.logEvent({
                name: "TakeNote",
                params: {
                  category: "Chat",
                },
              });
              props.handleNote(e);
              inputRef.current?.focus();
            }}
          >
            note
          </button>
          <button
            disabled={props.isLoading}
            type="submit"
            className="px-2 py-1 text-white rounded-xl disabled:opacity-50 disabled:cursor-not-allowed bg-sky-600 grow"
          >
            ▶︎
          </button>
        </div>
      </form>
    </>
  );
}

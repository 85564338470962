import GuideDialog from "./pieces/guide-dialog";
import { Box, Button, Typography } from "@mui/material";

import { isInStandaloneMode } from "../../util";

import { useEffect } from "react";

const LinkOut = () => {
  const isAndroid = /(android)/i.test(navigator.userAgent);
  const mainAction = isAndroid ? (
    <Typography
      border={"solid black 2px"}
      borderRadius={"0.5rem"}
      padding={"0.5rem"}
    >
      Android App: tap <strong>Add to Home screen</strong>/
      <strong>Install App</strong> from footer (or Chrome Options)
    </Typography>
  ) : (
    <>
      <Button
        href="https://apps.apple.com/us/app/diligent-daily-habits/id6449609066"
        variant="contained"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          // ReactGA.event({
          //   category: "GetAppModal",
          //   action: "get-ios",
          // });
        }}
      >
        iOS App Store
      </Button>
    </>
  );

  return (
    <Box textAlign="center" flex={"column"}>
      <Typography
        variant={"h4"}
        fontWeight={300}
        fontFamily={"Helvetica Neue"}
        mb={3}
        mt={2}
      >
        Diligent Daily Habits
      </Typography>

      <Box display="flex" flexDirection={"column"} mb={2} mt={3}>
        {mainAction}
        {/* <Button
          variant="outlined"
          style={{ marginTop: "2rem" }}
          onClick={() => {
            ReactGA.event({
              category: "GetAppModal",
              action: "start-subscribe",
            });

            nextStep();
          }}
        >
          Notify Me on Release
        </Button> */}
      </Box>
    </Box>
  );
};

function GetAppModal({ closeDialog, currentOpenDialog }) {
  useEffect(() => {
    if (isInStandaloneMode()) {
      // ReactGA.event({
      //   category: "GetAppModal",
      //   action: "pwa-installed",
      // });
    }
  }, []);

  return (
    <GuideDialog
      open={currentOpenDialog === GetAppModal.POPUP_KEY}
      onClose={closeDialog}
      action={"Close"}
    >
      <LinkOut />
    </GuideDialog>
  );
}

GetAppModal.POPUP_KEY = "GetAppModal";
GetAppModal.LAST_SEEN_KEY = "GetAppModal";

export default GetAppModal;

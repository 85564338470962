import update from "immutability-helper";
import { findDescendants, findParent } from "./data-structures";

// Linked to Item, need to manually copy data from habits entry.
// This is where data gets pulled into sortly format, home has saving logic for other way around.
const toSortlyLevel = (
  treeLevel,
  habit,
  nowDateTimeStr,
  entry = null,
  addHabitButton = false
) => {
  return {
    ...treeLevel,
    id: habit._id,
    name: habit.name,
    isNew: habit.isNew,
    value: (entry || {}).value || false,
    isTask: habit.dateStr === nowDateTimeStr,
    stability: habit.stability, // We need this since sortly levels are ephemerally generated late in the flow, and stability is first attached to the habit as soon as data is loaded, where all the entries are available.
    localAlarm: habit.localAlarm,
    hasAddHabit: addHabitButton,
  };
};

const findIndex = (sortlyLevels, id) => {
  return sortlyLevels.findIndex((item) => item.id === id);
};

const processLevels = function (assembledLevels) {
  const newLevels = [...assembledLevels];
  for (const [index, level] of assembledLevels.entries()) {
    let newLevel = level;
    // Mark levels that have children.
    const numChildren = findDescendants(assembledLevels, index).length;
    if (numChildren > 0) {
      newLevel = {
        ...newLevel,
        isCollapsed: newLevel.isCollapsed,
        hasChildren: true,
      };
    }

    // Hide and collapse any items that have parents collapsed.
    // Note that we want to use our updated newLevels as we go to make sure effects are cascaded correctly.
    const shallowParent = findParent(newLevels, index);
    if (!!shallowParent) {
      const parentIndex = findIndex(newLevels, shallowParent.id);
      if (newLevels[parentIndex].isCollapsed === true) {
        newLevel = {
          ...newLevel,
          isCollapsed: true,
          hidden: true,
        };
      }
    }

    if (index % 2 === 0) {
      newLevel = {
        ...newLevel,
        shade: true,
      };
    }

    newLevels[index] = newLevel;
  }

  return newLevels;
};

const generateSortlyLevels = function (
  treeLevels,
  habitById,
  entryByHabitId,
  nowDateTimeStr
): any[] {
  if (!treeLevels) {
    return null;
  }
  const realLevels = treeLevels.filter((treeLevel) => {
    const habit = habitById[treeLevel.id];
    if (!habit) {
      console.log("Expected habit for", treeLevel.id);
      console.log("In", habitById);
      return false;
    }
    return true;
  });
  const assembledLevels = realLevels.map((treeLevel, idx) => {
    const habit = habitById[treeLevel.id];
    const currentEntry = entryByHabitId[treeLevel.id];
    // If we're at the end of a routine or day, add a button to add a new habit.
    const addHabitButton =
      idx >= realLevels.length - 1 || realLevels[idx + 1].depth === 0;
    return toSortlyLevel(
      treeLevel,
      habit,
      nowDateTimeStr,
      currentEntry,
      addHabitButton
    );
  });

  return processLevels(assembledLevels);
};

const updateLevelValues = (sortlyLevels, index, newValue) => {
  if (sortlyLevels[index].value === newValue) {
    return sortlyLevels;
  }

  // If we have any children and we just got checked, we should auto collapse - if we got unchecked we should uncollapse.
  // const updatedIsCollapsed =
  //   findDescendants(sortlyLevels, index).length > 0 && newValue === true;

  // Update self.
  const stabilityTail = (sortlyLevels[index].stability || [0]).slice(1);
  const newStability = [newValue, ...stabilityTail];
  let updatedLevels = update(sortlyLevels, {
    [index]: {
      value: { $set: newValue },
      stability: { $set: newStability },
      // isCollapsed: { $set: updatedIsCollapsed },
    },
  });

  const shallowParent = findParent(updatedLevels, index);
  if (!!shallowParent) {
    const parentIndex = findIndex(updatedLevels, shallowParent.id);
    const shallowSiblings = findDescendants(updatedLevels, parentIndex);
    const siblingsAllTrue = !shallowSiblings.some((shallowSibling) => {
      const sibIndex = findIndex(updatedLevels, shallowSibling.id);
      return updatedLevels[sibIndex].value === false;
    });
    // Make parents checked if this completes the set.
    if (
      newValue === true &&
      updatedLevels[parentIndex].value === false &&
      siblingsAllTrue
    ) {
      updatedLevels = updateLevelValues(updatedLevels, parentIndex, true);
    } else if (
      // Make parents unchecked if this renders the set incomplete.
      newValue === false &&
      updatedLevels[parentIndex].value === true
    ) {
      updatedLevels = updateLevelValues(updatedLevels, parentIndex, false);
    }
  }

  const descendents = findDescendants(sortlyLevels, index);

  // Update children if needed.
  if (newValue === true) {
    for (const descendent of descendents) {
      const descendentIndex = findIndex(updatedLevels, descendent.id);
      if (updatedLevels[descendentIndex].value === false) {
        updatedLevels = updateLevelValues(updatedLevels, descendentIndex, true);
      }
    }
  } else {
    const descendentsAllTrue = !descendents.some((descendent) => {
      const desIndex = findIndex(updatedLevels, descendent.id);
      return updatedLevels[desIndex].value === false;
    });
    if (descendentsAllTrue) {
      for (const descendent of descendents) {
        const descendentIndex = findIndex(updatedLevels, descendent.id);
        if (updatedLevels[descendentIndex].value === true) {
          updatedLevels = updateLevelValues(
            updatedLevels,
            descendentIndex,
            false
          );
        }
      }
    }
  }

  return updatedLevels;
};

export { findIndex, generateSortlyLevels, updateLevelValues };

import { MASCOT_TYPES } from "../../util";
import GuideDialog from "./pieces/guide-dialog";
import { Box, Typography } from "@mui/material";

function DontAddNewModal({ closeDialog, currentOpenDialog, identityObj }) {
  return (
    <GuideDialog
      open={currentOpenDialog === DontAddNewModal.POPUP_KEY}
      onClose={closeDialog}
      action={"Got it"}
    >
      <Box textAlign="center">
        <Typography variant={"h3"} gutterBottom>
          {MASCOT_TYPES[identityObj.mascotType - 1]} ❗
        </Typography>
        <Typography mb={2}>
          <strong>Are you sure?</strong>
        </Typography>
        <Typography gutterBottom>
          Learning a new habit is easier when you have a stable routine.
        </Typography>
        <Typography gutterBottom>
          Work on making your habits more consistent first - maybe{" "}
          <strong>replace an existing habit</strong> that is new or weak.
        </Typography>
      </Box>
    </GuideDialog>
  );
}

DontAddNewModal.POPUP_KEY = "DontAddNewModal";
DontAddNewModal.LAST_SEEN_KEY = "DontAddNewModalLastSeen";

export default DontAddNewModal;

import {
  LocalAlarm,
  StreakHabit,
  getLocalHabitDateStr,
  isParentOnly,
  parseSimpleDateStr,
} from "./util";
import { differenceInCalendarDays, startOfDay, subDays } from "date-fns";
import update from "immutability-helper";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import maxBy from "lodash/maxBy";

export const findDescendants = (items: any[], index: number) => {
  const item = items[index];
  const descendants: typeof items = [];

  for (let i = index + 1; i < items.length; i += 1) {
    const next = items[i];

    if (next.depth <= item.depth) {
      break;
    }

    descendants.push(next);
  }

  return descendants;
};

export const findParent = (items: any[], index: number) => {
  if (index === 0) {
    return null;
  }

  const item = items[index];

  for (let i = index - 1; i >= 0; i -= 1) {
    const prev = items[i];
    if (prev.depth === item.depth - 1) {
      return prev;
    }
  }

  return null;
};

export const insert = (items: any[], data: any, targetIndex: number) => {
  const currentItemAtIndex = items[targetIndex];
  const currentItemDescendants = findDescendants(items, targetIndex);
  const { depth } = currentItemAtIndex;
  const newItem = { ...data, depth };

  return update(items, {
    $splice: [[targetIndex + currentItemDescendants.length + 1, 0, newItem]],
  });
};

export const remove = (items: any[], index: number) => {
  const descendants = findDescendants(items, index);

  return update(items, {
    $splice: [[index, descendants.length + 1]],
  });
};

type Item = {
  name: string;
  isNew?: boolean;
  value?: boolean;
  hidden?: boolean;
  isTask?: boolean;
  isCollapsed?: boolean;
  localAlarm?: LocalAlarm;
  stability?: Array<boolean>;
};

const createNewIdentity = (
  identity,
  identityAge,
  mascotType,
  mascotName,
  goal
) => {
  const dateTime = new Date();
  const dateStr = getLocalHabitDateStr(dateTime);
  const identityId = `identity_${dateStr}_${dateTime.toISOString()}_${identity}`;
  return {
    type: "identity",
    _id: identityId,
    _rev: 0,
    // Actual data.
    identity,
    identityAge,
    mascotType,
    mascotName,
    goal,
  };
};

// Use all the letters a->z to avoid conflicts with other ids. During any time slot.
const dummies = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
let dummyIndex = 0;
const getDummyId = () => {
  const dummyId = dummies[dummyIndex];
  dummyIndex = (dummyIndex + 1) % dummies.length;
  return dummyId;
};

const createNewHabit = (name, isTask) => {
  const dateTime = new Date();
  const dateStr = getLocalHabitDateStr(dateTime);
  const unique = name || getDummyId();
  const habitId = `habit_${dateStr}_${dateTime.toISOString()}_${unique}`;
  return {
    type: "habit",
    _id: habitId,
    _rev: 0,
    // Actual data.
    name,
    dateStr: isTask ? dateStr : undefined,
    alarm: null,
  };
};

const createNewEntry = (habitId, value, dateStr = null) => {
  if (!dateStr) {
    const dateTime = new Date();
    const dateStr = getLocalHabitDateStr(dateTime);
    return {
      type: "entry",
      _id: `entry_${dateStr}_${habitId}`,
      _rev: 0,
      // Actual data.
      habitId,
      value,
      dateStr,
    };
  }

  return {
    type: "entry",
    _id: `entry_${dateStr}_${habitId}`,
    _rev: 0,
    // Actual data.
    habitId,
    value,
    dateStr,
  };
};

const DEFAULT_PAST_STATS = {
  nonZeroDayCount: 0,
  entrySuccessCount: 0,
  diligentSuccessCount: 0,
};

const createNewTreeState = (treeLevels, treeAge, dateStrToSet, pastStats) => {
  const dateTime = new Date();
  const dateStr = dateStrToSet || getLocalHabitDateStr(dateTime);
  return {
    type: "treeState",
    _id: `treeState_${dateStr}_${dateTime.toISOString()}`,
    _rev: 0,
    // Actual data.
    treeLevels,
    dateStr,
    treeAge,
    pastStats: pastStats || cloneDeep(DEFAULT_PAST_STATS),
  };
};

const generateDefaultData = () => {
  // const habits = [
  //   createNewHabit("wake up (example)", false),
  //   createNewHabit("apply sunscreen by front door", false),
  //   createNewHabit("stand in sunny spot by fence", false),
  // ];

  // const treeLevels = [
  //   { id: habits[0]._id, depth: 0 },
  //   { id: habits[1]._id, depth: 1 },
  //   { id: habits[2]._id, depth: 1 },
  // ];
  const treeLevels = [];

  // const entries = [
  //   ...Array(20)
  //     .fill(0)
  //     .map((_, i) => {
  //       const pastDate = subDays(currentDate, i + 1);
  //       // Changing this to formatSimpleDateStr() might break the str matching for some reason?
  //       // Currently unclear what the str matching is used for.
  //       // https://stackoverflow.com/a/60368477.
  //       const pastDateStr = pastDate.toLocaleDateString("sv");
  //       const value = true;
  //       return createNewEntry(habits[1]._id, value, pastDateStr);
  //     }),
  //   ...Array(20)
  //     .fill(0)
  //     .map((_, i) => {
  //       const pastDate = subDays(currentDate, i + 2);
  //       const pastDateStr = pastDate.toLocaleDateString("sv");
  //       const value = i === 3 || i === 4 ? false : true;
  //       return createNewEntry(habits[0]._id, value, pastDateStr);
  //     }),
  //   ...Array(8)
  //     .fill(0)
  //     .map((_, i) => {
  //       const pastDate = subDays(currentDate, i + 1);
  //       const pastDateStr = pastDate.toLocaleDateString("sv");
  //       const value = i === 4 ? false : true;
  //       return createNewEntry(habits[2]._id, value, pastDateStr);
  //     }),
  // ];

  const treeState = createNewTreeState(treeLevels, 0, null, null);

  return {
    habits: [],
    treeState,
    entries: [],
  };
};

const DEFAULT_DATA = generateDefaultData();

// Best way to optimize this is to limit the entries we're using to only the ones we need.
const getStability = (todayLocalDateStr, entries = [], full = false) => {
  const todayDate = new Date(todayLocalDateStr);
  const stability = [];
  const validEntries = entries.filter((entry) => entry.dateStr);

  if (validEntries.length === 0) {
    return [false];
  }

  // Loop back one day at a time.
  for (let delta = 0; delta < validEntries.length; delta++) {
    const pastDate = subDays(todayDate, delta);
    // Changing this to formatSimpleDateStr() might break the str matching for some reason.
    const pastDateStr = pastDate.toISOString().split("T")[0];
    const success = validEntries.find((entry) => {
      return entry.dateStr === pastDateStr && entry.value === true;
    });
    stability.push(!!success);
    if (!full && delta > 22) {
      break;
    }
    if (full && delta > 200) {
      console.log("getStability() looped too many times.", validEntries);
      break;
    }
  }

  // Testing utility.
  // if (stability[0] === true) {
  //   return [true, true, true, true, true, true, true, true, true, true];
  // } else {
  //   return [
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //   ];
  // }
  return stability;
};

// Note that entries here contains all entries, not just the ones for one habit.
const getZeroDayStats = (todayLocalDateStr, entries = []) => {
  const todayDate = startOfDay(new Date(todayLocalDateStr));
  const zeroDays = [];
  const nonZeroDateStrs = new Set();

  let nonZeroDayCount = 0;
  let minDate = null;
  for (const entry of entries) {
    if (!entry.dateStr) {
      continue;
    }
    // Check if this is the date we have to work back to.
    const entryDate = startOfDay(parseSimpleDateStr(entry.dateStr));
    if (!minDate || entryDate < minDate) {
      minDate = entryDate;
    }
    // Count non-zero days.
    if (entry.value === true) {
      if (!nonZeroDateStrs.has(entry.dateStr)) {
        nonZeroDayCount++;
      }
      // Keep track of days that were non-zero.
      nonZeroDateStrs.add(entry.dateStr);
    }
  }

  // Always run for at least today (delta = 0).
  // Running for extra days doesn't do anything bad (just pads with false).
  const daysTracking = differenceInCalendarDays(todayDate, minDate);
  for (let delta = 0; delta <= daysTracking; delta++) {
    const pastDate = subDays(todayDate, delta);
    // Changing this to formatSimpleDateStr() might break the str matching for some reason.
    const pastDateStr = pastDate.toISOString().split("T")[0];
    const wasZeroDay = nonZeroDateStrs.has(pastDateStr);
    if (wasZeroDay) {
      zeroDays.push(true);
    } else {
      zeroDays.push(false);
    }
  }

  return { nonZeroDayCount, zeroDays };
};

// Ordered by most recent.
const attachHabitStability = (
  habit,
  entries = [],
  todayLocalDateStr,
  full = false
) => {
  return {
    ...habit,
    stability: getStability(todayLocalDateStr, entries, full),
  };
};

// From local DB.
const pullAllData = async (db, todayLocalDateStr) => {
  try {
    const start = performance.now();

    const result = await db.allDocs({
      include_docs: true,
    });
    console.log(
      `Alldocs ${result?.rows?.length} total, time since start:`,
      performance.now() - start
    );
    const dbItems = result?.rows?.map((row) => row.doc);
    // console.log('dbItems', result, dbItems);
    (window as any).diligentDebugDb = db;

    const cleanedData = cleanData(dbItems, todayLocalDateStr, db);
    // console.log(`Data cleaned, time since start:`, performance.now() - start);

    return cleanedData;
  } catch (e) {
    console.error("Could not query PouchDB:", e);
    return null;
  }
};

const getPastStats = (entries, parentOnlyIds) => {
  const nonZeroDateStrs = new Set();
  const pastStats = entries.reduce((ps, entry) => {
    if (entry.value === true) {
      if (!nonZeroDateStrs.has(entry.dateStr)) {
        // Keep track of days that were non-zero.
        nonZeroDateStrs.add(entry.dateStr);
        ps.nonZeroDayCount++;
      }
      if (entry.habitId === StreakHabit.HABIT_ID) {
        ps.diligentSuccessCount++;
      } else {
        // Do not count routine triggers.
        if (!parentOnlyIds.has(entry.habitId)) {
          ps.entrySuccessCount++;
        }
      }
    }
    return ps;
  }, cloneDeep(DEFAULT_PAST_STATS));

  return pastStats;
};

const mergeEntriesIntoStats = (pastStats, entries, parentOnlyIds) => {
  const deltaPastStats = getPastStats(entries, parentOnlyIds);
  // Add old values to new delta values.
  const newPastStats = Object.keys(pastStats).reduce((ps, key) => {
    ps[key] += pastStats[key] || 0;
    return ps;
  }, deltaPastStats);
  return newPastStats;
};

export const getParentOnlyIds = (treeLevels) => {
  return treeLevels.reduce((ids, level, i, orig) => {
    const set = new Set(ids);
    if (isParentOnly(level, orig[i + 1])) {
      set.add(level.id);
      return set;
    }
    return set;
  }, new Set());
};

// Note that these entries are INCLUSIVE of the last date, but EXCLUSIVE of today.
const migrateDayTreeState = (treeStateLast, entriesSince, nowLocalDateStr) => {
  // Historical tree age fields.
  const pastTreeAge = treeStateLast.treeAge || treeStateLast.age || 0;

  // Jack: Filter out single day tasks?
  // Build new pastStats.
  const oldPastStats = treeStateLast.pastStats || cloneDeep(DEFAULT_PAST_STATS);
  const newPastStats = mergeEntriesIntoStats(
    oldPastStats,
    entriesSince,
    getParentOnlyIds(treeStateLast.treeLevels)
  );

  return createNewTreeState(
    treeStateLast.treeLevels,
    pastTreeAge + 1,
    nowLocalDateStr,
    newPastStats
  );
};

const pickTreeState = (treeStates = []) => {
  // Use the treeState with the most history, and most recent usage/stats.
  // console.log("candidate TreeStates ", maxBy(treeStates, "treeAge"));
  const oldestAge = maxBy(treeStates, "treeAge").treeAge;
  // Shouldn't need this cause new day should add age???
  const allOldTrees = treeStates.filter((tree) => tree.treeAge === oldestAge);
  const bestTreeState =
    maxBy(allOldTrees, "dateStr") ||
    maxBy(
      treeStates,
      "_id" // Migrate old behavior.
    );
  // console.log("selected TreeState ", bestTreeState);
  return bestTreeState;
};

const getUpdatedTreeState = (treeStates, todayLocalDateStr, allEntries) => {
  const oldestTree = pickTreeState(treeStates);
  if (oldestTree.dateStr === todayLocalDateStr) {
    if (!oldestTree.pastStats) {
      // console.log("Migrating old treeState", treeStateToday)
      // Give stats to old tree states.
      const entriesSince = allEntries.filter(
        (entry) => entry.dateStr && entry.dateStr < todayLocalDateStr
      );
      return {
        ...oldestTree,
        pastStats: getPastStats(entriesSince, oldestTree.treeLevels),
      };
    }
    return oldestTree;
  }

  console.assert(
    oldestTree,
    "Using most recent possible tree to fill in today's tree."
  );

  const entriesSince = oldestTree.pastStats
    ? allEntries.filter(
        (entry) =>
          entry.dateStr >= oldestTree.dateStr &&
          entry.dateStr < todayLocalDateStr
      )
    : allEntries.filter((entry) => entry.dateStr < todayLocalDateStr);

  const newTreeStateToday = migrateDayTreeState(
    oldestTree,
    entriesSince,
    todayLocalDateStr
  );
  return newTreeStateToday;
};

const cleanData = (items, todayLocalDateStr, db) => {
  const groupedItems = groupBy(items, "type");

  // Get treeStates per day.
  const treeStates = groupedItems["treeState"] || [];
  // console.log("valid treestates", treeStates);
  // No treeStates at all, so create a new one.
  if (treeStates.length === 0) {
    const { habits, treeState, entries } = cloneDeep(DEFAULT_DATA);
    // These entries are in the past, so they won't be edites (or saved) anywhere else.
    db.bulkDocs([...entries]);
    return {
      allEntriesByHabitId: groupBy(entries, "habitId"),
      todaysEntries: [],
      savedHabits: habits,
      updatedTreeState: treeState,
      identityObj: createNewIdentity("", 0, null, null, null),
    };
  }

  // Today's entries.
  const allEntries = groupedItems["entry"] || [];
  const allEntriesByHabitId = groupBy(allEntries, "habitId");
  // Make an exception for the top streak entries, which we need history for.
  // Perhaps parse out allEntriesByHabitId instead?
  const todaysEntries = allEntries.filter(
    (item) =>
      item.dateStr === todayLocalDateStr ||
      item.habitId === StreakHabit.HABIT_ID
  );
  const savedHabits = groupedItems["habit"] || [];

  const updatedTreeState = getUpdatedTreeState(
    treeStates,
    todayLocalDateStr,
    allEntries
  );

  // Get most recent identity (should only be one).
  const savedIdentity =
    maxBy(groupedItems["identity"] || [], "identityAge") ||
    maxBy(groupedItems["identity"] || [], "_id") ||
    createNewIdentity("", 0, null, null, null);

  // Migrate old behavior.
  if (!savedIdentity.identityAge) {
    savedIdentity.identityAge = 0;
  }

  return {
    allEntriesByHabitId,
    todaysEntries,
    savedHabits,
    updatedTreeState,
    identityObj: savedIdentity,
  };
};

export {
  attachHabitStability,
  createNewHabit,
  createNewEntry,
  pullAllData,
  getStability,
  getZeroDayStats,
  createNewIdentity,
  mergeEntriesIntoStats,
  DEFAULT_PAST_STATS,
  DEFAULT_DATA,
};

export type { Item };

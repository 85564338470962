import { useState, useEffect, lazy } from "react";
// This one is only used to access a remote instance, so no need to hook it up to react.
import { Container, Box, Button, Typography, ButtonGroup } from "@mui/material";
import store from "store2";
import update from "immutability-helper";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import ModeIcon from "@mui/icons-material/Mode";

import { remove, insert, findDescendants } from "../data-structures";
import { CapacitorUpdater } from "@capgo/capacitor-updater";

import { useDebouncedCallback } from "use-debounce";
import {
  DAYS_IN_WEEK,
  DONE_ONBOARD_LEVEL,
  SQUARE_SIZE,
  arrayInsert,
  arrayRemove,
  formatSimpleDateStr,
  generateD1NotifDef,
  getAlarmId,
  isDiligentDay,
  isOnboardComplete,
  isPerfectDay,
  STREAK_DATES_TO_SHOW,
  isParentOnly,
  FILL_DELAY_MS,
  StreakHabit,
  LocalAlarm,
  TEST_MODE,
  getLocalHabitDateStr,
  NEXT_BUNDLE_VERSION,
  isInStandaloneMode,
} from "../util";
import {
  attachHabitStability,
  createNewHabit,
  createNewEntry,
  pullAllData,
  getStability,
  createNewIdentity,
  mergeEntriesIntoStats,
  getParentOnlyIds,
} from "../data-structures";
import {
  findIndex,
  generateSortlyLevels,
  updateLevelValues,
} from "../sortly-utils";

import { ONBOARD_LEVEL_KEY } from "../util";
import { NavLink } from "react-router-dom";
import CountUp from "react-countup";
import { LocalNotifications } from "@capacitor/local-notifications";
import {
  addDays,
  differenceInCalendarDays,
  format,
  isBefore,
  isEqual,
  isValid,
  parseISO,
  subDays,
} from "date-fns";
import DontAddNewModal from "../components/guidance/dont-add-new-modal";
import StartModal, {
  getTriggerForStarterHabit,
} from "../components/guidance/start-modal";
import GetAppModal from "../components/guidance/get-app-modal";
import Routine from "../components/routine";
import { SplashScreen } from "@capacitor/splash-screen";

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { App } from "@capacitor/app";
import * as Sentry from "@sentry/capacitor";
import ChatSection from "../components/coach/chat-section";
import MascotHeader from "../components/top-guidance/mascot-header";
import { Capacitor } from "@capacitor/core";
import differenceWith from "lodash/differenceWith";
import keyBy from "lodash/keyBy";
import size from "lodash/size";

const SyncManager = lazy(() => import("../components/sync-manager"));
const TopStreak = lazy(() => import("../components/top-streak"));

console.log("Home loaded", performance.now());
// Hide the splash (longer delay to hide flicker?)
SplashScreen.hide();
if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.notifyAppReady();
}

const START_EDITING_ICON = ModeIcon;
const END_EDITING_ICON = DoneOutlineRoundedIcon;

const CORE_CACHE_KEY = "core_cache";
const ALLOW_SYNC_KEY = "allow_sync";
const LAST_NOTIF_REJECTED_DATE_KEY = "last_notif_rejected_date";
const NOTIF_REJECTED_COUNT_KEY = "notif_rejected_count";
const CHANCE_FOR_FEEDBACK = 0.3;

// Main state that needs to be saved updated, everything else is derived.
// We diff this to see what needs to be saved.
// Jack: Figure out how to run deletion effectively.
const getSaveList = (state) => {
  const { habits, entries, treeState, identityObj } = state;
  // Strip the stability from habits before saving since we reconstruct it on read anyways.
  const habitsToSave = habits.map((h) => {
    const { stability, ...rest } = h;
    return rest;
  });
  return [...habitsToSave, ...entries, treeState, identityObj];
};

let cache = store(CORE_CACHE_KEY) || {};
console.log("cache loaded", performance.now());

const cacheNewState = (newState) => {
  // Don't slow down react rendering.
  setTimeout(() => {
    const { habits, entries, treeState, identityObj } = newState;
    const newCache = {
      habits,
      entries,
      treeState,
      identityObj,
      saveDate: getLocalHabitDateStr(new Date()),
    };
    console.log("cacheNewState");
    store(CORE_CACHE_KEY, newCache);
    cache = newCache;
  }, 0);
};

const mergeMissingEntries = (currentEntries, newEntries) => {
  if (!currentEntries) {
    return newEntries;
  }
  // Make map of current entries id to value.
  const currentEntriesMap = currentEntries.reduce((acc, e) => {
    acc[e._id] = e.value;
    return acc;
  }, {});

  // Make Set of ids for new entries.
  const newEntriesIds = new Set();
  const modifiedNewEntries = [];
  for (const newEntry of newEntries) {
    newEntriesIds.add(newEntry._id);
    if (currentEntriesMap[newEntry._id] === undefined) {
      modifiedNewEntries.push(newEntry);
    } else {
      // Optimistically use the current value of the entry.
      const currentValue = currentEntriesMap[newEntry._id];
      modifiedNewEntries.push({ ...newEntry, value: currentValue });
    }
  }
  const missingEntries = currentEntries.filter(
    (e) => !newEntriesIds.has(e._id)
  );
  return [...missingEntries, ...modifiedNewEntries];
};

// Very oddly coincidental that this is so similar to mergeMissingEntries. AI gen.
const mergeFirstStability = (currentHabits, newHabits) => {
  if (!currentHabits) {
    return newHabits;
  }
  const currentHabitsMap = currentHabits.reduce((acc, h) => {
    acc[h._id] = h.stability;
    return acc;
  }, {});
  const newHabitsIds = new Set();
  const modifiedNewHabits = [];
  for (const newHabit of newHabits) {
    newHabitsIds.add(newHabit._id);
    if (currentHabitsMap[newHabit._id] === undefined) {
      modifiedNewHabits.push(newHabit);
    } else {
      // Optimistically use the current stability of the habit.
      const currentStability = currentHabitsMap[newHabit._id];
      modifiedNewHabits.push({ ...newHabit, stability: currentStability });
    }
  }
  const missingHabits = currentHabits.filter((h) => !newHabitsIds.has(h._id));
  return [...missingHabits, ...modifiedNewHabits];
};

const getNewState = (
  prevState,
  habits,
  entries,
  treeState,
  identityObj,
  liveRemoteVersion,
  pouchedLocalVersion,
  mergeMissing
) => {
  // If we want to allow overrides, and there are new changes that we maybe missed.
  const overrideWithPrev =
    mergeMissing && prevState.liveLocalVersion > pouchedLocalVersion;
  // Optimistically merge in any new entries and stability from the previous state.
  const mergedEntries = overrideWithPrev
    ? mergeMissingEntries(prevState.entries, entries)
    : entries;
  const mergedHabits = overrideWithPrev
    ? mergeFirstStability(prevState.habits, habits)
    : habits;
  const newState = {
    ...prevState,
    habits: mergedHabits,
    entries: mergedEntries,
    treeState,
    identityObj,
    liveRemoteVersion,
    pouchedLocalVersion,
  };
  return newState;
};

export const useOnboardLevel = (useState) => {
  const [onboardLevel, setOnboardLevel] = useState(
    store(ONBOARD_LEVEL_KEY) || 0
  );
  return [
    onboardLevel,
    (newLevel) => {
      store(ONBOARD_LEVEL_KEY, newLevel);
      setOnboardLevel(newLevel);
    },
  ];
};

const dummyAudio = {
  play: () => console.log("Audio not loaded yet"),
};
let tickAudio = dummyAudio,
  tackAudio = dummyAudio,
  tackAudioQuiet = dummyAudio;

setTimeout(async () => {
  const { Howl } = await import("howler");
  // const { FilePicker } = await import("@capawesome/capacitor-file-picker");
  // const result = await FilePicker.pickFiles();
  // const file = result.files[0];
  // console.log(file);
  // const { Filesystem, Directory } = await import("@capacitor/filesystem");

  // const docs = await Filesystem.readdir({
  //   path: "/",
  //   directory: Directory.Documents,
  // });
  // console.log("documents", docs);

  // const libs = await Filesystem.readdir({
  //   path: "/",
  //   directory: Directory.Library,
  // });
  // console.log("libraries", libs);
  // const none = await Filesystem.readdir({
  //   path: "/",
  // });
  // console.log("none", none);
  // const dat = await Filesystem.readdir({
  //   path: "/",
  //   directory: Directory.Data,
  // });
  // console.log("dat", dat);
  // const cache = await Filesystem.readdir({
  //   path: "/",
  //   directory: Directory.Cache,
  // });
  // console.log("none", cache);

  tickAudio = new Howl({
    src: ["tick.mp3"],
    volume: 0.02,
  });
  tackAudio = new Howl({
    src: ["tack.mp3"],
    volume: 0.03,
  });

  tackAudioQuiet = new Howl({
    src: ["tack.mp3"],
    volume: 0.003,
  });
}, 10 * 1000);

const MIN_DATE_OPACITY = 0.2;
const MAX_DATE_OPACITY = 0.4;

const LAST_BUNDLE_CHECK_KEY = "last_bundle_check";
let lastBundleCheck = store(LAST_BUNDLE_CHECK_KEY) || null;
let newSavedBundle = null;
const MS_IN_DAY = 1000 * 60 * 60 * 24;
// NODE_ENV cannot be overwritten.
const BUNDLE_CHECK_INTERVAL = TEST_MODE ? 40 * 1000 : 2 * MS_IN_DAY;
App.addListener("appStateChange", async (state) => {
  console.log("lastBundleCheck", lastBundleCheck);
  if (!lastBundleCheck) {
    // Never checked, set first interval.
    const time = Date.now();
    store(LAST_BUNDLE_CHECK_KEY, time);
    lastBundleCheck = time;
    return;
  }
  const shouldCheck = Date.now() - lastBundleCheck > BUNDLE_CHECK_INTERVAL;
  console.log("App state should check:", shouldCheck);
  if (!shouldCheck) {
    console.log(
      "App waiting to check bundle:",
      BUNDLE_CHECK_INTERVAL - (Date.now() - lastBundleCheck)
    );
  }
  if (shouldCheck && state.isActive && newSavedBundle === null) {
    console.log("Trying to DL", NEXT_BUNDLE_VERSION);
    store(LAST_BUNDLE_CHECK_KEY, Date.now());
    // Ensure download occurs while the app is active, or download may fail
    try {
      newSavedBundle = await CapacitorUpdater.download({
        url: `https://diligent-app.s3.us-west-2.amazonaws.com/${NEXT_BUNDLE_VERSION}.zip`,
        version: NEXT_BUNDLE_VERSION,
      });
      console.log("Downloaded new version", newSavedBundle);
    } catch (e) {
      console.error("Error downloading new version", e);
      Sentry.captureException(e);
    }
  }

  // Activate the update when the application is sent to background
  if (!state.isActive && newSavedBundle) {
    // Activate the update when the application is sent to background
    SplashScreen.show();
    try {
      await CapacitorUpdater.set(newSavedBundle);
      // At this point, the new version should be active, and will need to hide the splash screen
    } catch (e) {
      SplashScreen.hide();
      console.error("Error setting new version", e);
      Sentry.captureException(e);
      newSavedBundle = null; // Potentially retry.
    }
  }
});

const HomePage = ({ db }) => {
  const nowDatetime = new Date();
  if (Capacitor.isNativePlatform()) {
    CapacitorUpdater.notifyAppReady();
  }
  const nowLocalDateStr = getLocalHabitDateStr(nowDatetime);

  // Haven't opened the app yet today - all stabilities are out of date.
  if (
    cache &&
    cache.saveDate &&
    cache.saveDate !== nowLocalDateStr &&
    cache.habits &&
    cache.habits.length > 0
  ) {
    cache.habits.forEach((habit) => {
      if (habit && habit.stability && habit.stability.length > 0) {
        habit.stability = [false, ...habit.stability];
      }
    });
  }

  const [state, setState] = useState({
    // Core State.
    // Prior, unclear cause, cached habit stabilities might be corrupted?
    habits: cache.habits || [],
    entries: cache.entries || [],
    treeState: cache.treeState || null,
    identityObj: cache.identityObj || {},

    // Sync state.

    // Local pouch loop (for saving changes):
    // liveLocalVersion -> pouchedLocalVersion -> pushedLocalVersion(SyncManager).
    // Increments when we have react state changes.
    liveLocalVersion: 0,
    // Increments when react state changes get saved to PouchDB.
    pouchedLocalVersion: 0,

    // Remote pouch loop (loading): pouchedRemoteVersion -> liveRemoteVersion.
    // Increments when remote is synced into PouchDB.
    // Since there's no way to know if the remote is updated, we start at 1.
    pouchedRemoteVersion: 1,
    // Increments when react state is loaded from PouchDB that includes remote sync changes.
    liveRemoteVersion: 0,

    suggestedIdentityWidth: 0,
  });

  const [showConfetti, setShowConfetti] = useState(false);
  const [bounceStats, setBounceStats] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [openDialog, setOpenDialog] = useState(null);
  const [currentSavedList, setCurrentSavedList] = useState([]);
  const onboardLevelState = useOnboardLevel(useState);
  const [onboardLevel, setOnboardLevel] = onboardLevelState;
  const parsed_dontAddSeenDate = parseISO(store(DontAddNewModal.LAST_SEEN_KEY));
  const [dontAddSeenDate, setDontAddSeenDate] = useState(
    isValid(parsed_dontAddSeenDate) ? parsed_dontAddSeenDate : null
  );
  const parsed_startModalSeenDate = parseISO(store(StartModal.LAST_SEEN_KEY));
  const [startModalSeenDate, setStartModalSeenDate] = useState(
    isValid(parsed_startModalSeenDate) ? parsed_startModalSeenDate : null
  );
  const parsed_lastRejectedD1Date = parseISO(
    store(LAST_NOTIF_REJECTED_DATE_KEY)
  );
  const [lastRejectedD1Date, setLastRejectedD1Date] = useState(
    isValid(parsed_lastRejectedD1Date) ? parsed_lastRejectedD1Date : null
  );
  const [rejectedCount, setRejectedCount] = useState(
    store(NOTIF_REJECTED_COUNT_KEY) || 0
  );
  const [activatedSync, setActivatedSync] = useState(
    store(ALLOW_SYNC_KEY) || false
  );
  console.log("activatedSync", activatedSync);
  // Still the first day.
  const isFirstDay =
    differenceInCalendarDays(nowDatetime, startModalSeenDate) < 1;
  const seenStartModal = isValid(startModalSeenDate);
  const [notifsAllowed, setNotifsAllowed] = useState(null);
  const [hideStreaks, setHideStreaks] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);

  const activeAlarmIds = state?.treeState?.treeLevels?.map((level) => {
    return getAlarmId(level.id);
  });
  useEffect(() => {
    if (
      activeAlarmIds &&
      notifsAllowed === null &&
      state?.liveRemoteVersion === 1
    ) {
      // console.log("Check for orphaned alarms");
      LocalNotifications.checkPermissions()
        .then((res) => {
          switch (res.display) {
            case "granted":
              setNotifsAllowed(true);
              // Cancel alarms that no longer show up in the tree.

              LocalNotifications.getPending()
                .then((res) => {
                  const existingAlarms = new Set();
                  for (const notif of res.notifications) {
                    if (
                      !activeAlarmIds.includes(notif.id) || // Habit no longer has alarm.
                      existingAlarms.has(notif.id) // Duplicates.
                    ) {
                      LocalNotifications.cancel({
                        notifications: [notif],
                      }).catch((err) => console.log("CantCancel", err));
                    } else {
                      existingAlarms.add(notif.id);
                    }
                  }
                })
                .catch((err) => console.log("CantWipeNotifs", err));
              break;
            default:
              setNotifsAllowed(false);
              break;
          }
        })
        .catch((err) => console.log("CheckNotifPermissions", err));
    }
  }, [
    setNotifsAllowed,
    activeAlarmIds,
    notifsAllowed,
    state?.liveRemoteVersion,
  ]);

  useEffect(() => {
    if (state?.treeState?.treeAge > 200) {
      setOnboardLevel(DONE_ONBOARD_LEVEL);
    }
  }, [state.treeState, setOnboardLevel]);

  // Load from PouchDB in 2 different cases:
  // 1. Initial load => replace all.
  // 2. Remote updated => replace all.
  const currentPouchedRemoteVersion = (state || {}).pouchedRemoteVersion;
  // This effect covers cases 1 and 2.
  // PULL IN BACKGROUND.
  useEffect(() => {
    const runAsyncInitialLoad = async () => {
      console.log("starting load", performance.now());
      const pullResult = await pullAllData(db, nowLocalDateStr);

      if (!pullResult) {
        console.log("PouchLocal: initial load pull failed - no data");
        return;
      }

      const {
        allEntriesByHabitId,
        todaysEntries,
        savedHabits,
        updatedTreeState,
        identityObj,
      } = pullResult;

      setState((prevState) => {
        const activeHabitIds = updatedTreeState.treeLevels.map((level) => {
          return level.id;
        });

        const stabilizedHabits = savedHabits.map((currentHabit) => {
          if (!activeHabitIds.includes(currentHabit._id)) {
            return currentHabit;
          }
          return attachHabitStability(
            currentHabit,
            allEntriesByHabitId[currentHabit._id],
            nowLocalDateStr
          );
        });

        // console.log("PouchLocal: initial load state from Pouch");

        // Find entry _ids in state that are new - catch the common edge case where a user quickly ticked a habit after opening.

        const newState = getNewState(
          prevState,
          stabilizedHabits,
          todaysEntries,
          updatedTreeState,
          identityObj,
          prevState.pouchedRemoteVersion, // CHANGED: Any Pouched remote data is now live.
          prevState.pouchedLocalVersion, // Unchanged: No local changes have been processed.
          true
        );
        // If we have new default data, manually save everything just in case.
        if (newState.treeState.treeAge === 0) {
          setCurrentSavedList([]);
        } else {
          setCurrentSavedList(getSaveList(newState));
        }

        cacheNewState(newState);
        return newState;
      });
    };
    runAsyncInitialLoad();
  }, [db, nowLocalDateStr, currentPouchedRemoteVersion]); // Only run if we just initialized, or pulled from remote.

  // Save changes if we have local updates.
  // 2 runs per save - second will be ignored since we're just updating revs and save status.
  // We might extra time: once blocked by debounce before real save.
  useEffect(() => {
    // Second effect run gets blocked since we just updated pouchedLocalVersion to match liveLocalVersion.
    if (state.liveLocalVersion <= state.pouchedLocalVersion) {
      return;
    }
    const allDocs = getSaveList(state);

    // Get the documents that have been changed.
    // We could track changes as we go but it's actually pretty hard since each change could have cascaded effects on the tree.
    const changedDocs = differenceWith(allDocs, currentSavedList, isEqual);
    if (changedDocs.length === 0) {
      console.log("PouchLocal: no changes to save");
      return;
    }

    const updateRevs = (newDocs) => {
      const idToRev = newDocs.reduce((acc, doc) => {
        if (doc.error) {
          throw new Error(`Doc save error: ${doc}`);
        }
        acc[doc.id] = doc.rev;
        return acc;
      }, {});
      // This set state runs twice to help test issues in strict mode?
      setState((prevState) => {
        // Run through the state types that we need to check for new revs.
        // Check each new immutable copy against our id map.
        const newHabits = prevState.habits.map((habit) => {
          const newRev = idToRev[habit._id];
          if (newRev) {
            return {
              ...habit,
              _rev: newRev,
            };
          }
          return habit;
        });

        const newEntries = prevState.entries.map((entry) => {
          const newRev = idToRev[entry._id];
          if (newRev) {
            return {
              ...entry,
              _rev: newRev,
            };
          }
          return entry;
        });

        const treeStateNewRev = idToRev[prevState.treeState._id];
        const newTreeState = treeStateNewRev
          ? {
              ...prevState.treeState,
              _rev: treeStateNewRev,
            }
          : prevState.treeState;

        const identityObjNewRev = idToRev[prevState.identityObj._id];
        const newIdentityObj = identityObjNewRev
          ? {
              ...prevState.identityObj,
              _rev: identityObjNewRev,
            }
          : prevState.identityObj;

        const newState = getNewState(
          prevState,
          newHabits,
          newEntries,
          newTreeState,
          newIdentityObj,
          prevState.liveRemoteVersion, // Unchanged: we haven't touched remote.
          prevState.liveLocalVersion, // CHANGED: we've just caught up on local changes.
          false // We are confident that we should override the old state.
        );
        cacheNewState(newState);
        setCurrentSavedList(getSaveList(newState));
        return newState;
      });
    };

    console.log("PouchLocal: saving", changedDocs);
    db.bulkDocs(changedDocs).then((updated) => {
      // Map from id to rev.
      // If there are any errors, we need a full reload.
      try {
        updateRevs(updated);
      } catch (e) {
        console.log(
          "PouchLocal: error saving, will re-load doc revs and try again",
          e
        );
        const docIds = changedDocs.map((doc) => doc._id);
        db.allDocs({ keys: docIds, include_docs: false })
          .then((docs) => {
            console.log("got new revs", docs);
            updateRevs(
              docs.rows.map((row) => {
                return { id: row.id, rev: row.value.rev };
              })
            );
          })
          .then(() => {
            setTimeout(() => {
              setState((prevState) => {
                return {
                  ...prevState,
                  liveLocalVersion: prevState.liveLocalVersion + 1,
                };
              });
            }, 300);
          });
      }
    });
  }, [state, currentSavedList, db]);

  // Instead of updating liveLocalVersion directly, we debounce the change to batch any rapid changes.
  const incrementLiveLocalVersionDebounced = useDebouncedCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        liveLocalVersion: prevState.liveLocalVersion + 1,
      };
    });
  }, 1000).callback;

  // MAYBEDO: Hack to prevent weird crashes, may not be needed anymore?
  if (!state) {
    console.log("no state");
    return "...";
  }

  const habitById = keyBy(state?.habits, "_id");
  const entryByHabitId = keyBy(state?.entries, "habitId");

  const sortlyLevels = generateSortlyLevels(
    state?.treeState?.treeLevels,
    habitById,
    entryByHabitId,
    nowLocalDateStr
  );

  if (sortlyLevels === null || sortlyLevels === undefined) {
    console.log("no sortly levels");
    return "...";
  }

  const updateState = (newSortlyLevels, saveDelayed = false) => {
    const newTreeLevelsState = [];
    const newHabitsState = [];
    const newEntriesState = [];

    // Scrape out the tree levels info.
    for (const sortlyLevel of newSortlyLevels) {
      // Strip out the value, which is saved as an entry.
      const { value, ...habitInfo } = sortlyLevel;
      const currentHabit = habitById[habitInfo.id];
      const newHabit = {
        ...habitInfo,
        ...createNewHabit(habitInfo.name, habitInfo.isTask),
      };
      if (!currentHabit) {
        newHabitsState.push(newHabit);
      } else {
        const updatedHabit = {
          ...currentHabit,
          ...habitInfo, // Also catches isNew Changes ugh.
        };
        newHabitsState.push(updatedHabit);
      }

      const currentEntry = entryByHabitId[habitInfo.id];
      const newEntry = createNewEntry(habitInfo.id, !!sortlyLevel.value);
      const updatedEntry = {
        ...currentEntry,
        value: !!sortlyLevel.value,
      };
      if (!currentEntry) {
        newEntriesState.push(newEntry);
      } else {
        newEntriesState.push(updatedEntry);
      }

      // Scrape out the tree levels info.
      newTreeLevelsState.push({
        id: habitInfo.id || newHabit._id,
        depth: sortlyLevel.depth,
        isCollapsed: sortlyLevel.isCollapsed,
        // cache isTask here?
      });
    }

    // Diligent dummy entries only.
    let streakEntryToday = null;
    const priorStreakEntries = state.entries.filter((entry) => {
      if (
        entry.dateStr === nowLocalDateStr &&
        entry.habitId === StreakHabit.HABIT_ID
      ) {
        streakEntryToday = entry;
      }
      return (
        entry.habitId === StreakHabit.HABIT_ID &&
        entry.dateStr !== nowLocalDateStr
      );
    });

    newEntriesState.push(...priorStreakEntries);
    // Cannot make a new entry all the time because we have to track revisions for syncing.
    // Probably best to stick with the paradigm we're already working with.
    // Convert this to a separate state being tracked - a diligent streak struct or something.
    const todayDiligentDay = isDiligentDay(newSortlyLevels);
    const updatedStreakEntryToday = streakEntryToday
      ? { ...streakEntryToday, value: todayDiligentDay }
      : createNewEntry(StreakHabit.HABIT_ID, todayDiligentDay);
    newEntriesState.push(updatedStreakEntryToday);

    setState((prevState) => {
      const newTreeState = {
        ...prevState.treeState,
        treeLevels: newTreeLevelsState,
        treeAge: prevState.treeState.treeAge + 1,
      };

      // We increment separately for delayed saves.
      const liveLocalVersion = saveDelayed
        ? prevState.liveLocalVersion
        : prevState.liveLocalVersion + 1;

      return {
        ...prevState,
        habits: newHabitsState,
        entries: newEntriesState,
        treeState: newTreeState,
        liveLocalVersion,
      };
    });
    if (saveDelayed) {
      incrementLiveLocalVersionDebounced();
    }
  };

  // unfortuately we rely on the order of these habits later in top streak.
  const habitsById = keyBy(state.habits, "_id");
  const activeHabits = state.treeState.treeLevels.map((level) => {
    return habitsById[level.id];
  });

  const appUseAge = differenceInCalendarDays(nowDatetime, startModalSeenDate);
  const weakCount = activeHabits.reduce((weakCount, habit) => {
    const stab = habit.stability || [];
    // Last 3 days have been explicit misses.
    const recentlyMissed =
      stab.length >= 4 &&
      stab[1] === false &&
      stab[2] === false &&
      stab[3] === false;
    const recentStability = stab.slice(0, DAYS_IN_WEEK);
    const recentCompletions = recentStability.filter((c) => c).length;
    const lowCompletionRate = recentCompletions <= 2;

    if (recentlyMissed && lowCompletionRate) {
      return weakCount + 1;
    }
    return weakCount;
  }, 0);
  const dontAddNew = activeHabits.length > 4 && appUseAge > 4 && weakCount > 2;

  const blockAdd =
    (!isValid(dontAddSeenDate) ||
      differenceInCalendarDays(nowDatetime, dontAddSeenDate) > 3) &&
    dontAddNew;

  const requestD1 = (level?) => {
    const usedLevel =
      level ||
      sortlyLevels.find((l, i, arr) => {
        return l.depth === 1 || (l.depth === 0 && !isParentOnly(l, arr[i + 1]));
      });
    console.log("usedLevel", usedLevel);
    const setActiveD1Notif = () => {
      // Will always clear based on a static ID... hopefully.
      const currentHabitName = usedLevel.name;
      const newDef = generateD1NotifDef(currentHabitName);
      // This should replace the existing alarm since we always use the same ID.
      LocalNotifications.schedule({
        notifications: [newDef],
      })
        .then((res) => {
          FirebaseAnalytics.logEvent({
            name: `d1_${newDef.title}`,
            params: {
              category: "Main",
            },
          });
          console.log("d1", newDef);
        })
        .catch((err) => {
          FirebaseAnalytics.logEvent({
            name: `d1_failed`,
            params: {
              category: "Main",
            },
          });
          console.log("d1 failed", err);
        });
    };

    if (notifsAllowed) {
      setActiveD1Notif();
      // Check last rejection date.
    } else if (
      !isValid(lastRejectedD1Date) ||
      differenceInCalendarDays(nowDatetime, lastRejectedD1Date) >
        rejectedCount * 7
    ) {
      setTimeout(() => {
        LocalNotifications.requestPermissions().then((res) => {
          if (res.display === "granted") {
            setNotifsAllowed(true);
            setActiveD1Notif();
            FirebaseAnalytics.logEvent({
              name: `d1_accepted`,
              params: {
                category: "Main",
              },
            });
          } else {
            store(LAST_NOTIF_REJECTED_DATE_KEY, nowDatetime.toISOString());
            setLastRejectedD1Date(nowDatetime);
            store(NOTIF_REJECTED_COUNT_KEY, rejectedCount + 1);
            setRejectedCount(rejectedCount + 1);
            FirebaseAnalytics.logEvent({
              name: `d1_rejected`,
              params: {
                category: "Main",
              },
            });
          }
        });
      }, 2000);
    } else {
      FirebaseAnalytics.logEvent({
        name: `d1_notAllowed`,
        params: {
          category: "Main",
        },
      });
      console.log(
        "notifs not allowed",
        !isValid(lastRejectedD1Date) ||
          differenceInCalendarDays(nowDatetime, lastRejectedD1Date) >
            rejectedCount * 7,
        lastRejectedD1Date,
        rejectedCount
      );
    }
  };
  // Probably used by the drag and drop system.
  const handleChange = (newSortlyLevels: any[]) => {
    // If any new levels are deeper than followup habit, then we reject the change.
    const tooDeepLevels = newSortlyLevels.filter((level) => {
      return level.depth > 1;
    });
    if (tooDeepLevels.length > 0) {
      return;
    }
    // ReactGA.event({
    //   category: "TreeEdit",
    //   action: "drag",
    // });
    updateState(newSortlyLevels, true);
  };
  const handleChangeName = (id: string, name: string) => {
    const index = findIndex(sortlyLevels, id);
    const depth = sortlyLevels[index].depth;
    const newSortlyLevels = update(sortlyLevels, {
      [index]: { name: { $set: name }, isNew: { $set: false } },
    });
    updateState(newSortlyLevels);
    FirebaseAnalytics.logEvent({
      name: "NameChange",
      params: {
        category: "Main",
        depth: depth,
        name: name,
      },
    });
  };
  const handleChangeAlarm = (id: string, localAlarm?: LocalAlarm) => {
    // ReactGA.event({
    //   category: "TreeEdit",
    //   action: "alarm",
    // });
    const index = findIndex(sortlyLevels, id);
    const newSortlyLevels = update(sortlyLevels, {
      [index]: { localAlarm: { $set: localAlarm } },
    });
    updateState(newSortlyLevels);
  };
  // Also removes trigger is ok?
  const handleDelete = (id: string) => {
    const index = findIndex(sortlyLevels, id);
    const level = sortlyLevels[index];
    // ReactGA.event({
    //   category: `TreeEditDelete-${level.depth}`,
    //   action: level.name,
    // });
    const newSortlyLevels = remove(sortlyLevels, index);
    if (notifsAllowed) {
      LocalNotifications.cancel({
        notifications: [{ id: getAlarmId(id) }],
      }).catch((err) => console.log("CantCancelOnDelete", err));
    }

    updateState(newSortlyLevels);

    // Reward use for deleting?
    if (
      !isPerfectDay(sortlyLevels) &&
      isPerfectDay(newSortlyLevels) &&
      !showConfetti
    ) {
      setShowConfetti(true);
    }
  };
  // const handleFullWipe = () => {
  //   ReactGA.event({
  //     category: "TreeEdit",
  //     action: "wipe",
  //   });
  //   const notificationObjs = sortlyLevels.map((level) => {
  //     return { id: getAlarmId(level.id) };
  //   });
  //   const newSortlyLevels = [];
  //   if (notifsAllowed) {
  //     LocalNotifications.cancel({ notifications: notificationObjs }).catch(
  //       (err) => console.log("CantCancelOnFullWipe", err)
  //     );
  //   }
  //   updateState(newSortlyLevels);
  // };
  const handleCollapse = (id: string) => {
    const index = findIndex(sortlyLevels, id);
    const level = sortlyLevels[index];
    // ReactGA.event({
    //   category: "TreeEditCollapse",
    //   action: `collapse-${level.name}`,
    // });
    const newIsCollapsed = !level.isCollapsed;
    const newSortlyLevels = update(sortlyLevels, {
      [index]: { isCollapsed: { $set: newIsCollapsed } },
    });
    updateState(newSortlyLevels);
  };
  const handleInsertSingle = (id) => {
    // Add one regular habit
    const newSortlyLevel = {
      id: null,
      name: "",
      depth: 1,
      isNew: true,
    };
    if (sortlyLevels.length === 0) {
      throw new Error("Cannot insert single habit into empty list");
    }
    // Note that this is insert after, and will copy the existing element's depth (might be 0 if no other habits).
    const currentIndex = findIndex(sortlyLevels, id);
    const newSortlyLevels = insert(sortlyLevels, newSortlyLevel, currentIndex);
    // Make sure regular habit is always at depth 1.
    newSortlyLevels[currentIndex + 1].depth = 1;
    updateState(newSortlyLevels);
  };
  const handleAddTriggerName = (id) => {
    const currentTriggerIndex = findIndex(sortlyLevels, id);
    const currentTrigger = sortlyLevels[currentTriggerIndex];
    if (
      !currentTrigger ||
      currentTrigger.name !== null ||
      currentTrigger.depth !== 0
    ) {
      throw new Error(
        `Cannot add trigger name to invalid trigger: ${currentTrigger}`
      );
    }
    const newTriggerLevel = {
      ...currentTrigger,
      name: "",
      isNew: true,
    };
    // Replace the old trigger object with the new one.
    // Note that this is insert after, and will copy the existing element's depth.
    const currentIndex = findIndex(sortlyLevels, id);
    // Note that we should try to avoid the sortly util functions where possible - they have weird logic.
    const newSortlyLevels = arrayInsert(
      sortlyLevels,
      newTriggerLevel,
      currentIndex + 1
    );

    console.log(
      "handleAddTriggerName",
      sortlyLevels,
      newTriggerLevel,
      currentIndex
    );
    const completeLevels = arrayRemove(newSortlyLevels, currentIndex);
    updateState(completeLevels);
  };
  // See createNewHabit.
  const handleClickNewRoutine = () => {
    // ReactGA.event({
    //   category: "TreeEdit",
    //   action: "new-routine",
    // });
    const newTrigger = {
      id: null,
      name: null,
      depth: 0,
      isNew: true,
    };
    // const withTrigger = add(sortlyLevels, newTrigger);
    // updateState(withTrigger);
    const newHabit = {
      id: null,
      name: "",
      depth: 1,
    };
    const newSortlyLevels = [...sortlyLevels, newTrigger, newHabit];
    updateState(newSortlyLevels);
    return;
  };
  const handleSetFirstHabit = (habit) => {
    FirebaseAnalytics.logEvent({
      name: "FirstHabit" + Capacitor.getPlatform(),
      params: {
        category: "Progression",
      },
    });
    const newTrigger = {
      id: null,
      name: getTriggerForStarterHabit(habit),
      depth: 0,
    };
    // const withTrigger = add(sortlyLevels, newTrigger);
    // updateState(withTrigger);
    const newHabit = {
      id: null,
      name: habit,
      depth: 1,
    };
    const newSortlyLevels = [...sortlyLevels, newTrigger, newHabit];
    updateState(newSortlyLevels);
    return;
  };
  const insertHabit = (id: string) => {
    // ReactGA.event({
    //   category: "TreeEdit",
    //   action: "insert-habit",
    // });
    const index = findIndex(sortlyLevels, id);
    const newSortlyLevel = {
      id: null,
      name: "",
      isNew: true,
    };
    const newSortlyLevels = insert(sortlyLevels, newSortlyLevel, index);
    // Have to set this manually afterwards since the insert will override with the depth of the current item.
    newSortlyLevels[index + 1].depth = 1;
    updateState(newSortlyLevels);
  };
  const handleEditing = (isEditing) => {
    setHideStreaks(isEditing);
  };
  const handleChangeValue = (id: string, value) => {
    const index = findIndex(sortlyLevels, id);
    const newSortlyLevels = updateLevelValues(sortlyLevels, index, value);

    updateState(newSortlyLevels);
    if (value) {
      const wasDiligent = isDiligentDay(sortlyLevels);
      const isNowDiligent = isDiligentDay(newSortlyLevels);
      const turnedDiligent = !wasDiligent && isNowDiligent;
      const turnedPerfect = isPerfectDay(newSortlyLevels) && !showConfetti;
      const sound = turnedPerfect ? tickAudio : tackAudio;
      // occasionally submit the action for a reaction from the chat.
      if (Math.random() < CHANCE_FOR_FEEDBACK && pendingAction === null) {
        console.log("set pending action");
        const type = turnedPerfect
          ? "perfect"
          : turnedDiligent
          ? "diligent"
          : "regular";
        setPendingAction([type, id]);
      }
      setTimeout(() => {
        sound.play();
      }, FILL_DELAY_MS);

      if (turnedPerfect) {
        FirebaseAnalytics.logEvent({
          name: "Tick",
          params: {
            category: "Main",
            type: "perfect",
          },
        });
        setShowConfetti(true);
      } else if (turnedDiligent) {
        FirebaseAnalytics.logEvent({
          name: "Tick",
          params: {
            category: "Main",
            type: "diligent",
          },
        });
      } else {
        FirebaseAnalytics.logEvent({
          name: "Tick",
          params: {
            category: "Main",
            type: "regular",
          },
        });
      }

      const level = newSortlyLevels[index];
      const isCollapsed = level.isCollapsed;
      if (isCollapsed) {
        const numChildren = findDescendants(newSortlyLevels, index).length;
        FirebaseAnalytics.logEvent({
          name: "Tick",
          params: {
            category: "Main",
            type: "collapsed",
          },
        });
        if (numChildren > 1) {
          const delayDings = (dings) => {
            if (dings < 1) {
              return;
            }
            setTimeout(() => {
              tackAudioQuiet.play();
              delayDings(dings - 1);
            }, 2 * FILL_DELAY_MS);
          };
          // We already play a sound for the top level, so we only play for children-1.
          setTimeout(() => {
            delayDings(numChildren - 1);
          }, FILL_DELAY_MS);
        }
      }
      setBounceStats(true);
      setTimeout(() => {
        setBounceStats(false);
      }, 1000);
      // If no alarms manually set.
      const hasManualAlarms = newSortlyLevels.some(
        (level) => level.localAlarm && level.localAlarm.localHr
      );
      if (
        !hasManualAlarms &&
        isOnboardComplete(onboardLevel) &&
        appUseAge > 2
      ) {
        const habitCount = size(newSortlyLevels);
        // Chance of replacing an existing d1 with a later completed habit.
        const chance = Math.floor(Math.random() * 2);
        if (habitCount < 3 || chance === 0) {
          requestD1(level);
        }
      }
    }
    if (!value) {
      setShowConfetti(false);
    }
  };
  const handleChangeIdentity = (identity, mascotType, mascotName, goal) => {
    const onlyChangedIdentity = !mascotType && !mascotName;
    setState((prevState) => {
      const newLiveLocalVersion = onlyChangedIdentity
        ? prevState.liveLocalVersion
        : prevState.liveLocalVersion + 1;
      if (prevState.identityObj) {
        const newIdentityObj = {
          ...prevState.identityObj,
          identity: identity || prevState.identityObj.identity,
          mascotType: mascotType || prevState.identityObj.mascotType,
          mascotName: mascotName || prevState.identityObj.mascotName,
          goal: goal || prevState.identityObj.goal,
          identityAge: (prevState.identityObj.identityAge || 66) + 1,
        };
        return {
          ...prevState,
          liveLocalVersion: newLiveLocalVersion,
          identityObj: newIdentityObj,
        };
      } else {
        return {
          ...prevState,
          liveLocalVersion: newLiveLocalVersion,
          identityObj: createNewIdentity(
            identity || "",
            0,
            mascotType,
            mascotName,
            goal
          ),
        };
      }
    });
    if (onlyChangedIdentity) {
      incrementLiveLocalVersionDebounced();
    }
  };

  // Tells react to reload data from pouchdb since we received changes from remote.
  const onPouchUpdated = () => {
    setState((prevState) => {
      return {
        ...prevState,
        pouchedRemoteVersion: prevState.pouchedRemoteVersion + 1,
      };
    });
  };

  const getSyncStatus = () => {
    if (state.pouchedRemoteVersion === 0) {
      return "updating";
    }
    if (state.liveLocalVersion > state.pouchedLocalVersion) {
      return "saving";
    }
    if (state.liveRemoteVersion < state.pouchedRemoteVersion) {
      return "loading";
    }
    return "synced";
  };

  const syncPassIndex = sortlyLevels[0]?.name?.indexOf("sync");
  const activateSyncSecret = syncPassIndex > -1;
  if (activateSyncSecret && !activatedSync) {
    console.log("activating sync", activateSyncSecret);
    // Write to local storage to activate sync.
    store(ALLOW_SYNC_KEY, true);
    setActivatedSync(true);
  }
  const maybeSyncManager = activatedSync ? (
    <SyncManager
      db={db}
      onPouchUpdated={onPouchUpdated}
      // Will get propagated to pushedLocalVersion.
      pouchedLocalVersion={state.pouchedLocalVersion}
      // We don't push until there's no more pending changes.
      isLocalStabilized={state.pouchedLocalVersion === state.liveLocalVersion}
      status={getSyncStatus()}
    ></SyncManager>
  ) : null;

  const streakStability = getStability(
    nowLocalDateStr,
    state.entries.filter((entry) => entry.habitId === StreakHabit.HABIT_ID)
  );

  const currentStats = state.treeState.pastStats
    ? mergeEntriesIntoStats(
        state.treeState.pastStats,
        state.entries,
        getParentOnlyIds(sortlyLevels)
      )
    : null;
  const statsTotal =
    0 +
    (currentStats.entrySuccessCount || 0) +
    (currentStats.diligentSuccessCount || 0) +
    (currentStats.nonZeroDayCount || 0);
  const statsClass = bounceStats ? "bounce" : "";

  // Set starting date to be either today, yesterday, or day before, based on the earliest success we have.
  // Need to pass this to item streak to stay coordinated.
  const maxSuccessDelta = activeHabits.reduce((maxDelta, habit) => {
    const stab = habit.stability || [];
    for (let i = stab.length - 1; i >= maxDelta; i--) {
      if (stab[i]) {
        return i;
      }
    }
    return maxDelta;
  }, 0);
  const shownMaxSuccessDelta = Math.min(
    maxSuccessDelta,
    STREAK_DATES_TO_SHOW - 1
  );
  const firstSuccessDateStr = getLocalHabitDateStr(
    subDays(nowDatetime, shownMaxSuccessDelta)
  );
  const firstSuccessDate = parseISO(firstSuccessDateStr);
  const historyDatesToShow =
    differenceInCalendarDays(parseISO(nowLocalDateStr), firstSuccessDate) + 1;
  const dayDiffs = Array.from({ length: STREAK_DATES_TO_SHOW }, (_, i) => i);
  const opacityChange =
    (MAX_DATE_OPACITY - MIN_DATE_OPACITY) / STREAK_DATES_TO_SHOW;

  // Get array of date labels starting with the first success date.
  const dateLabels = dayDiffs.map((i) => {
    const datetime = addDays(firstSuccessDate, i);
    const dayOfWeek = format(datetime, "EEEEEE");
    const dayOfMonth = format(datetime, "dd");
    const isBeforeToday = isBefore(datetime, nowDatetime);
    const dateOpacity = isBeforeToday
      ? MIN_DATE_OPACITY + i * opacityChange
      : MAX_DATE_OPACITY;
    const isToday = nowLocalDateStr === formatSimpleDateStr(datetime);
    const baseStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: SQUARE_SIZE,
      width: SQUARE_SIZE,
      fontSize: "0.8rem",
      lineHeight: "0.8rem",
      opacity: dateOpacity,
    };
    const style = isToday
      ? { ...baseStyle, fontWeight: "700", opacity: 0.8 }
      : baseStyle;
    return (
      <Box key={i} width={SQUARE_SIZE} sx={style}>
        <div>{dayOfWeek}</div>
        <div>{dayOfMonth}</div>
      </Box>
    );
  });

  // RightPad stabilities with false to equal lengths.
  const longestStability = Math.max(
    ...sortlyLevels.map((h) => h.stability?.length || 1)
  );
  const paddedLevels = sortlyLevels.map((h) => {
    const stab = h.stability || [false];
    return {
      ...h,
      stability: stab.concat(
        new Array(longestStability - stab.length).fill(false)
      ),
    };
  });

  // Split sortly levels into routine blocks.
  const routineBlocks = paddedLevels.reduce((acc, level) => {
    if (level.depth === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(level);
    return acc;
  }, []);

  if (!seenStartModal && !isOnboardComplete(onboardLevel)) {
    return (
      <StartModal
        onSetFirstHabit={(habit) => {
          handleSetFirstHabit(habit);
          setStartModalSeenDate(nowDatetime);
          store(StartModal.LAST_SEEN_KEY, nowDatetime.toISOString());
        }}
        onSetGoal={(goal) => {
          handleChangeIdentity(null, null, null, goal);
        }}
        goal={state.identityObj.goal}
      ></StartModal>
    );
  }
  // Migrate over the pyreddit chat section
  // install ai from next
  // install small version of tailwind? - copy over config?
  // // test connection with my backend
  // const chatPassIndex = sortlyLevels[0]?.name?.indexOf("mil");
  const allowChat =
    Capacitor.isNativePlatform() || isInStandaloneMode() || true;

  const reflectionChat = allowChat ? (
    <>
      <MascotHeader
        mascotType={state.identityObj.mascotType}
        mascotName={state.identityObj.mascotName || "Coach"}
        dateStr={nowLocalDateStr}
      ></MascotHeader>
      <ChatSection
        identityStr={state.identityObj.identity}
        sortlyLevels={sortlyLevels}
        dateStr={nowLocalDateStr}
        pendingAction={pendingAction}
        clearPendingAction={() => {
          setPendingAction(null);
        }}
      ></ChatSection>
    </>
  ) : null;

  return (
    <Container
      maxWidth="md"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <Box mb={1} pt={5}>
        <TopStreak
          stability={streakStability}
          currDate={nowDatetime}
          nowLocalDateStr={nowLocalDateStr}
          isFullyLoaded={
            maybeSyncManager === null || getSyncStatus() === "synced"
          }
          onChangeIdentity={(indentity) =>
            handleChangeIdentity(indentity, null, null, null)
          }
          onChangeMascotType={(type) =>
            handleChangeIdentity(null, type, null, null)
          }
          onChangeMascotName={(name) =>
            handleChangeIdentity(null, null, name, null)
          }
          requestD1={requestD1}
          identityObj={state.identityObj}
          suggestedWidth={state.suggestedIdentityWidth}
          stabilizedHabits={activeHabits}
          onboardLevelState={onboardLevelState}
          bounce={bounceStats}
          isFirstDay={isFirstDay}
          notifsState={[notifsAllowed, setNotifsAllowed]}
          stats={currentStats}
        ></TopStreak>

        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"end"}
          alignContent={"center"}
          marginRight={"0.5rem"}
          mt={1}
        >
          {
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-around"}
              visibility={isEditMode || hideStreaks ? "hidden" : "visible"}
              mb={-0.5}
            >
              {dateLabels}
            </Box>
          }
        </Box>
        {size(sortlyLevels) !== 0 && (
          <Box minHeight={"20vh"}>
            {routineBlocks.map((routine, i) => {
              const routineRows = routine;
              const handlers = {
                handleChange,
                handleChangeName,
                handleChangeAlarm,
                handleDelete,
                handleCollapse,
                handleInsertSingle,
                handleAddTriggerName,
                handleChangeValue,
                handleEditing,
                insertHabit,
              };
              const data = {
                nowLocalDateStr: nowLocalDateStr,
                sortlyLevels: sortlyLevels,
                onboardLevelState: onboardLevelState,
                showAddHabitButtons: isEditMode,
                historyDatesToShow: historyDatesToShow,
                hideStreaks: hideStreaks || isEditMode,
              };
              return (
                <Routine
                  key={i}
                  routineRows={routineRows}
                  handlers={handlers}
                  data={data}
                ></Routine>
              );
            })}
            {isEditMode && (
              <Button
                sx={{
                  marginRight: "auto",
                  marginLeft: "auto",
                  display: "block",
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  textTransform: "none",
                }}
                variant="outlined"
                onClick={handleClickNewRoutine}
              >
                new routine
              </Button>
            )}
          </Box>
        )}
        {size(sortlyLevels) === 0 && (
          <Box minHeight={"20vh"} pt={8}>
            <Typography variant={"h4"} align="center">
              🌱
            </Typography>
            {isOnboardComplete(onboardLevel) && (
              <Button
                sx={{
                  marginRight: "auto",
                  marginLeft: "auto",
                  display: "block",
                  marginTop: "2rem",
                  borderRadius: "1rem",
                }}
                variant="contained"
                onClick={handleClickNewRoutine}
              >
                new routine
              </Button>
            )}
          </Box>
        )}
        {reflectionChat}
        {maybeSyncManager && (
          <Box mt={4} mr={2} ml="auto" width={"fit-content"}>
            {maybeSyncManager}
          </Box>
        )}

        <Box
          position={"fixed"}
          display={"flex"}
          justifyContent={"space-around"}
          sx={{ bottom: "0.5rem", width: "100%" }}
        >
          {isEditMode ? (
            <ButtonGroup variant="outlined" aria-label="Done">
              <Button
                sx={{
                  background: "#FFFFFFCC",
                  borderRadius: "0.6rem",
                }}
                onClick={() => {
                  setIsEditMode(!isEditMode);
                }}
              >
                <END_EDITING_ICON
                  sx={{ fontSize: "1rem", marginRight: "0.3rem" }}
                ></END_EDITING_ICON>{" "}
                Done
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="outlined" aria-label="Nav">
              <Button
                sx={{
                  background: "#FFFFFFCC",
                  borderRadius: "0.6rem",
                }}
                onClick={() => {
                  if (blockAdd) {
                    // ReactGA.event({
                    //   category: "TreeEdit",
                    //   action: "block-add",
                    // });
                    setOpenDialog(DontAddNewModal.POPUP_KEY);
                    return;
                  }
                  // ReactGA.event({
                  //   category: "UI",
                  //   action: "toggle-hide-add",
                  // });
                  setIsEditMode(!isEditMode);
                }}
              >
                <START_EDITING_ICON
                  sx={{ fontSize: "1rem", marginRight: "0.3rem" }}
                ></START_EDITING_ICON>{" "}
                Add
              </Button>
              <Button
                color="info"
                sx={{
                  background: "#FFFFFFCC",
                  borderRadius: "0.6rem",
                }}
                onClick={() => {
                  FirebaseAnalytics.logEvent({
                    name: "SeeJourney",
                    params: {
                      category: "Main",
                    },
                  });
                }}
              >
                <NavLink
                  className={statsClass}
                  to="/journey"
                  style={{ textDecoration: "none" }}
                >
                  Journey (
                  {
                    <CountUp
                      preserveValue
                      end={statsTotal}
                      duration={2}
                    ></CountUp>
                  }
                  )
                </NavLink>
              </Button>
            </ButtonGroup>
          )}
        </Box>
        <DontAddNewModal
          currentOpenDialog={openDialog}
          closeDialog={() => {
            setDontAddSeenDate(nowDatetime);
            store(DontAddNewModal.LAST_SEEN_KEY, nowDatetime.toISOString());
            setOpenDialog(null);
          }}
          identityObj={state.identityObj}
        />
        <GetAppModal
          currentOpenDialog={openDialog}
          closeDialog={() => {
            setOpenDialog(null);
          }}
        ></GetAppModal>
      </Box>
    </Container>
  );
};

export default HomePage;

import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  styled,
} from "@mui/material";
import HabitRow from "./habit-row";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
const COLLAPSE_ICON = PlayArrowIcon;

const getAddTriggerButton = (handleAddTriggerName, trigger) => {
  if (trigger.name !== null) {
    return null;
  }
  return (
    <Button
      sx={{
        borderRadius: "1rem",
        border: "1px solid",
        textTransform: "lowercase",
        paddingTop: 0,
        paddingBottom: 0,
        height: "fit-content",
        marginRight: "1rem",
      }}
      onClick={(e) => {
        handleAddTriggerName(trigger.id);
      }}
    >
      prepend starting trigger
    </Button>
  );
};

const getAddHabitButton = (handleInsertSingle, routineRows) => {
  return (
    <Button
      sx={{
        borderRadius: "1rem",
        border: "1px solid",
        textTransform: "lowercase",
        paddingTop: 0,
        paddingBottom: 0,
        height: "fit-content",
      }}
      onClick={(e) => {
        handleInsertSingle(routineRows[routineRows.length - 1].id);
      }}
    >
      stack new habit
    </Button>
  );
};

const ACCORDIAN_ICON_SIZE = "1.1rem";
const ACCORDIAN_ICON_BUTTON_SIZE = "1.9rem";
const ACCORDIAN_BUTTON_EXTRA_SPACE = "0.8rem"; // For pressing, to the right.
const ROW_OFFSET = "2.5rem"; // For leaf habit nodes.

const CleanAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
  margin: 0,
  background: "transparent",
}));

const CleanAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(0.3),
  marginLeft: ROW_OFFSET,
}));

const CleanDotSummary = styled((props: React.PropsWithChildren<{}>) => (
  <AccordionSummary {...props} expandIcon={<span>⏺</span>}>
    {props.children}
  </AccordionSummary>
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: 0, // HabitRow already has Margin. Put on icon instead for more click space
    cursor: "default",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: ACCORDIAN_BUTTON_EXTRA_SPACE,
    height: ACCORDIAN_ICON_BUTTON_SIZE,
    width: ACCORDIAN_ICON_BUTTON_SIZE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
    cursor: "default",
  },
  "&.Mui-focusVisible": {
    // THIS SPACE SHITß
    backgroundColor: "transparent",
  },
  padding: 0,
  marginLeft: theme.spacing(0.5),
  minHeight: theme.spacing(4),
  height: theme.spacing(4),
  cursor: "default",
}));

const CleanTriangleSummary = styled((props: React.PropsWithChildren<{}>) => (
  <AccordionSummary
    {...props}
    expandIcon={
      <COLLAPSE_ICON
        sx={{
          fontSize: ACCORDIAN_ICON_SIZE,
          opacity: 0.6,
        }}
      />
    }
  >
    {props.children}
  </AccordionSummary>
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: 0, // HabitRow already has Margin. Put on icon instead for more click space
    cursor: "default",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: ACCORDIAN_BUTTON_EXTRA_SPACE,
    height: ACCORDIAN_ICON_BUTTON_SIZE,
    width: ACCORDIAN_ICON_BUTTON_SIZE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
    cursor: "pointer",
  },
  "&.Mui-focusVisible": {
    // THIS SPACE SHITß
    backgroundColor: "transparent",
  },
  padding: 0,
  marginLeft: theme.spacing(0.5),
  minHeight: theme.spacing(4),
  height: theme.spacing(4),
  cursor: "default",
}));

const Routine = ({ routineRows, handlers, data }) => {
  const {
    handleInsertSingle,
    handleCollapse,
    handleAddTriggerName,
    ...habitHandlers
  } = handlers;
  const {
    nowLocalDateStr,
    sortlyLevels,
    onboardLevelState,
    showAddHabitButtons,
    historyDatesToShow,
    hideStreaks,
  } = data;
  const [trigger, ...habits] = routineRows;
  const orphanedTrigger = habits.length === 0;
  if (trigger.depth !== 0) {
    throw new Error("trigger depth must be 0");
  }

  const onCollapse = (e) => {
    e.stopPropagation();
    handleCollapse(trigger.id);
  };

  const addHabitButton = showAddHabitButtons
    ? getAddHabitButton(handleInsertSingle, routineRows)
    : null;

  const addTriggerButton = showAddHabitButtons
    ? getAddTriggerButton(handleAddTriggerName, trigger)
    : null;

  if (orphanedTrigger) {
    console.log("orphaned trigger");
  }
  const routineContent = (
    <>
      {habits.map((habit) => (
        <HabitRow
          {...habit}
          key={habit.id + "habit"}
          handlers={habitHandlers}
          nowLocalDateStr={nowLocalDateStr}
          sortlyLevels={sortlyLevels}
          onboardLevelState={onboardLevelState}
          showAddHabitButtons={showAddHabitButtons}
          historyDatesToShow={historyDatesToShow}
          hideEntries={hideStreaks}
        />
      ))}
      {showAddHabitButtons && (
        <Box
          key={trigger.id + "add"}
          display={"flex"}
          justifyContent={"start"}
          mt={1}
          mb={1}
        >
          {addTriggerButton}
          {addHabitButton}
        </Box>
      )}
    </>
  );
  const Summary = orphanedTrigger ? CleanDotSummary : CleanTriangleSummary;

  // Only allow collapse if there is a trigger
  const wrappedContent =
    trigger.name !== null ? (
      <CleanAccordion
        expanded={!trigger.isCollapsed && !orphanedTrigger}
        onChange={orphanedTrigger ? () => {} : onCollapse}
      >
        <Summary key={trigger.id + "summary"}>
          {trigger.name !== null && (
            <HabitRow
              {...trigger}
              handlers={habitHandlers}
              nowLocalDateStr={nowLocalDateStr}
              sortlyLevels={sortlyLevels}
              onboardLevelState={onboardLevelState}
              showAddHabitButtons={showAddHabitButtons}
              historyDatesToShow={historyDatesToShow}
              hideEntries={
                hideStreaks || (!orphanedTrigger && !trigger.isCollapsed)
              }
              lowerEntries={!orphanedTrigger && trigger.isCollapsed}
            ></HabitRow>
          )}
        </Summary>
        <CleanAccordionDetails key={trigger.id + "details"}>
          {routineContent}
        </CleanAccordionDetails>
      </CleanAccordion>
    ) : (
      routineContent
    );

  const orgStyle = showAddHabitButtons
    ? { marginBottom: "2rem", background: "white" }
    : { marginBottom: "0.5rem" };
  const subs = habits.flatMap((h, i) => {
    const isDone = h.stability[0];
    const name = (
      <span
        key={h.id + "sub"}
        className={isDone ? "strike" : ""}
        style={{
          color: isDone ? "#bbb" : "#444",
          whiteSpace: "nowrap",
        }}
      >
        {h.name}
      </span>
    );
    if (i !== 0) {
      return [
        <span style={{ color: "#bbb" }} key={h.id + "comma"}>
          ,{" "}
        </span>,
        name,
      ];
    }
    return name;
  });

  return (
    <Box paddingTop={1} paddingBottom={1} sx={orgStyle}>
      {wrappedContent}

      {trigger.isCollapsed && (
        // See ML LINEUP in habit-row.tsx
        <Box
          key={"collapsed"}
          fontSize="smaller"
          ml={ROW_OFFSET}
          sx={{ maxWidth: "45%", lineHeight: "1rem" }}
        >
          {subs}
        </Box>
      )}
    </Box>
  );
};

export default Routine;

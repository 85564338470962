import React, { lazy } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import OGPouch from "pouchdb";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/capacitor";

import upsertPlugin from "pouchdb-upsert";

import HomePage from "./pages/home";

const JourneyPage = lazy(() => import('./pages/journey'));

OGPouch.plugin(upsertPlugin);

const DILIGENT_DB_NAME = "diligent";

const history = createBrowserHistory();

history.listen((location) => {
  window.scrollTo(0, 0);
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('Error boundary caught error', error);
    Sentry.captureException(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      <div role="alert">
      <p>Something went wrong, please let <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:diligentdailyhabits@gmail.com"
        >
          DiligentDailyHabits@gmail.com
        </a> know:</p>

      <pre style={{ color: "red" }}>{this.state.error.message}</pre>
      <p>Heads up: If you re-install the app, you will lose your data!
      </p>
    </div>
    }

    return this.props.children; 
  }
}

export default function BasicExample() {
  const localDB = new OGPouch(DILIGENT_DB_NAME);

  return (
    <ErrorBoundary>
      <React.Suspense>
        <CssBaseline />
          <Router history={history}>
            <Switch>
            <Route
              key='journey'
              exact path="/journey">
                <JourneyPage db={localDB} />
              </Route>
              <Route 
              key='home' exact path="/">
                <HomePage db={localDB} />
              </Route>
            </Switch>
          </Router>
      </React.Suspense>
    </ErrorBoundary>
  );
}

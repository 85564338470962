import { startOfDay, subDays } from "date-fns";

import {
  COLOR_ATTENTION_ACCENT,
  SQUARE_SIZE,
  getItemHash,
  parseSimpleDateStr,
  COLOR_EMPTY_BAR,
  STREAK_DATES_TO_SHOW,
  COLOR_SUBTLE_GOOD_RGB,
} from "../util";
import compact from "lodash/compact";

const CURVE_SIZE = "0.4rem";
const GREEN_CURVE_SIZE = "0.33rem"; // Account for being smaller vs border.

// const getPlantSpan = (
//   icon,
//   rarity,
//   beforeSuccess,
//   afterSuccess,
//   backgroundColor,
//   opacityMultiplier,
//   isToday,
//   hash
// ) => {
//   const className = rarity >= 1 ? "marked shine" : "marked";
//   const opacity = Math.max(0.06, 0.6 * opacityMultiplier);

//   const borderLeftRadius = beforeSuccess ? "0px" : OLD_SPAN_RADIUS;
//   const borderRightRadius = afterSuccess ? "0px" : OLD_SPAN_RADIUS;
//   const border =
//     rarity >= 2
//       ? `outset 1px rgba(48, 238, 48, ${isToday ? opacityMultiplier : opacity})`
//       : rarity >= 1
//       ? `dashed 1px rgba(48, 238, 48, ${isToday ? opacityMultiplier : opacity})`
//       : "";

//   // Note that we nest the spans so that they can have independent opacities.
//   if (isToday) {
//     return (
//       <span
//         style={{
//           fontSize: "larger",
//           borderTopRightRadius: OLD_SPAN_RADIUS,
//           borderBottomRightRadius: OLD_SPAN_RADIUS,
//           borderTopLeftRadius: beforeSuccess ? 0 : OLD_SPAN_RADIUS,
//           borderBottomLeftRadius: beforeSuccess ? 0 : OLD_SPAN_RADIUS,
//           marginRight: END_MARGIN,
//           border: border,
//           background: backgroundColor,
//         }}
//         className={className}
//       >
//         <span
//           style={{
//             opacity: 1,
//           }}
//         >
//           {icon}
//         </span>
//       </span>
//     );
//   }

//   // Note that we nest the spans so that they can have independent opacities.
//   return (
//     <span
//       style={{
//         fontSize: "larger",
//         background: backgroundColor,
//         borderTopLeftRadius: borderLeftRadius,
//         borderBottomLeftRadius: borderLeftRadius,
//         borderTopRightRadius: borderRightRadius,
//         borderBottomRightRadius: borderRightRadius,
//         border: border,
//         marginLeft: "0px",
//       }}
//       className={className}
//     >
//       <span
//         style={{
//           opacity: opacity,
//         }}
//         className={className}
//       >
//         {icon}
//       </span>
//     </span>
//   );
// };

// const getMarkedIcon = (
//   success,
//   hash,
//   isToday,
//   beforeSuccess,
//   afterSuccess,
//   isBridge,
//   opacityMultiplier
// ) => {
//   const shouldBreathe = !success && isToday;
//   const className = shouldBreathe ? "marked animate-breathe" : "marked";
//   const backgroundOpacity = 0.25 + 0.2 * opacityMultiplier;
//   const backgroundColor = `rgba(160, 252, 160, ${backgroundOpacity})`;
//   const lighterBackgroundColor = `rgba(247, 255, 247, 0.97)`;
//   const uncheckedColor = `rgba(204, 119, 0, 0.7)`;

//   if (!success) {
//     // if (isBridge) {
//     //   return (
//     //     <span
//     //       style={{
//     //         fontSize: "larger",
//     //         verticalAlign: "bottom",
//     //         borderLeft: "solid 1px transparent",
//     //         borderRight: "solid 1px transparent",
//     //         background: backgroundColor,
//     //         marginLeft: "0px",
//     //         color: "#cccccc00",
//     //         paddingBottom: "0.2rem",
//     //         paddingTop: "0.2rem",
//     //       }}
//     //       className={className}
//     //     >
//     //       <span
//     //         style={{
//     //           marginLeft: "0.15rem",
//     //           marginRight: "0.15rem",
//     //           height: "100%",
//     //           borderRadius: "0.4rem",
//     //           color: "#cccccc00",
//     //           backgroundColor: lighterBackgroundColor,
//     //         }}
//     //         className={className}
//     //       >
//     //         {"🌱"}
//     //       </span>
//     //     </span>
//     //   );
//     // }

//     // Empty today, unchecked habits.
//     if (isToday) {
//       return (
//         <span
//           style={{
//             border: `solid 2px ${uncheckedColor}`,
//             borderRadius: "0.4rem",
//             opacity: 1,
//           }}
//           className={className}
//         >
//           {/* <span
//             style={{
//               marginLeft: "0.15rem",
//               marginRight: "0.15rem",
//               height: "100%",
//               border: `solid 2px ${uncheckedColor}`,
//               borderRadius: "0.4rem",
//               color: "#cccccc00",
//             }}
//             className={className}
//           >
//             {"🌱"}
//           </span> */}
//         </span>
//       );
//     }

//     // Completely empty misses between successes.
//     return (
//       <span
//         style={{
//           fontSize: "smaller",
//           verticalAlign: "bottom",
//           borderRadius: "1em",
//           marginLeft: "0px",
//           opacity: 0.5,
//           color: "#cccccc",
//         }}
//         className={className}
//       >
//         {FULL_MISS_EMOJI}
//       </span>
//     );
//   }
//   return getPlantSpan(
//     getItemSuccessIcon(hash),
//     getItemSuccessRarity(hash),
//     beforeSuccess,
//     afterSuccess,
//     backgroundColor,
//     opacityMultiplier,
//     isToday,
//     hash
//   );
// };

const hadSuccess = (arr: Boolean[], idx) => {
  if (!arr || idx < 0 || idx >= arr.length) {
    return false;
  }

  try {
    return arr[idx];
  } catch (e) {
    return false;
  }
};

const ItemStreak = ({
  nowLocalDateStr,
  stability,
  id,
  onTap,
  confettiRef,
  stabilityAbove, // at least empty array if the row exists, null if not.
  stabilityBelow,
}) => {
  const safeStability = stability && stability.length > 0 ? stability : [false];

  const dateNaught = startOfDay(parseSimpleDateStr(nowLocalDateStr));
  // const dayOfWeek = dateNaught.getDay(); // 0 is sunday.
  // const earlierDaysInWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  // Filter out if before the current calendar week.
  // Else, including today, get the hash.
  // const stabilityWithHash = compact(
  //   safeStability.slice(0, earlierDaysInWeek + 1).map((stab, index) => {
  //     if (!stab) return [stab, 0];
  //     const entryDate = subDays(dateNaught, index);

  //     // id is the habit id
  //     return [stab, getItemHash(id, entryDate)];
  //   })
  // );

  const stabilityWithHash = compact(
    safeStability.slice(0, STREAK_DATES_TO_SHOW).map((stab, index) => {
      if (!stab) return [stab, 0];
      const entryDate = subDays(dateNaught, index);

      // id is the habit id
      return [stab, getItemHash(id, entryDate)];
    })
  );

  const doneToday = stability[0];
  const MIN_OPACITY = doneToday ? 0.5 : 0.2;
  const MAX_OPACITY = doneToday ? 1 : 0.5;

  const marks = stability.map((success, i, arr) => {
    const isToday = i === 0;

    // Border radius for each corner, based on completed items around it.
    // Remember that we're currently in reverse of display order
    const beforeSuccess = hadSuccess(stability, i + 1);
    const afterSuccess = hadSuccess(stability, i - 1);
    const aboveSuccess = hadSuccess(stabilityAbove, i);
    const belowSuccess = hadSuccess(stabilityBelow, i);

    const br1 = !beforeSuccess && !aboveSuccess ? GREEN_CURVE_SIZE : 0;
    const br2 = !aboveSuccess && !afterSuccess ? GREEN_CURVE_SIZE : 0;
    const br3 = !afterSuccess && !belowSuccess ? GREEN_CURVE_SIZE : 0;
    const br4 = !belowSuccess && !beforeSuccess ? GREEN_CURVE_SIZE : 0;
    const onClick = () => {
      if (isToday) {
        onTap();
      }
    };
    const ref = isToday ? confettiRef : null;

    const opacityDropPerDay = (MAX_OPACITY - MIN_OPACITY) / arr.length;
    const startOpacity = MAX_OPACITY - opacityDropPerDay * (i + 1);
    const endOpacity = MAX_OPACITY - opacityDropPerDay * i;

    // Is success
    if (success) {
      if (!!isToday) {
        return (
          <div
            key={i}
            style={{
              width: SQUARE_SIZE,
              height: SQUARE_SIZE,
            }}
            onClick={onClick}
          >
            <div
              className="fillBar"
              style={{
                display: "flex",
                color: "white",
                alignItems: "center",
                paddingLeft: "0.1rem", // Otherwise looks uncentered.
                justifyContent: "center",
                fontWeight: 800,
                height: SQUARE_SIZE,
                background: `linear-gradient(to right, rgba(${COLOR_SUBTLE_GOOD_RGB},${startOpacity}), rgba(${COLOR_SUBTLE_GOOD_RGB},${endOpacity}))`,
                borderRadius: `${br1} ${br2} ${br3} ${br4}`,
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
              onClick={onClick}
              ref={ref}
            >
              ✓
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={i}
            style={{
              width: SQUARE_SIZE,
              height: SQUARE_SIZE,
            }}
            onClick={onClick}
          >
            <div
              style={{
                width: SQUARE_SIZE,
                height: SQUARE_SIZE,
                background: `linear-gradient(to right, rgba(${COLOR_SUBTLE_GOOD_RGB},${startOpacity}), rgba(${COLOR_SUBTLE_GOOD_RGB},${endOpacity}))`,
                borderRadius: `${br1} ${br2} ${br3} ${br4}`,
              }}
            ></div>
          </div>
        );
      }
    }

    // Is skip or extra credit
    // Is miss today show red.
    if (isToday) {
      return (
        <div
          key={i}
          style={{
            width: SQUARE_SIZE,
            height: SQUARE_SIZE,
            borderRadius: CURVE_SIZE,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <div
            className="animate-breathe"
            style={{
              width: "40%",
              height: "40%",
              backgroundColor: COLOR_ATTENTION_ACCENT,
              borderRadius: "5rem",
            }}
            onClick={onClick}
            ref={ref}
          ></div>
        </div>
      );
    }

    // Is miss
    return (
      <div
        key={i}
        style={{
          width: SQUARE_SIZE,
          height: SQUARE_SIZE,
          borderRadius: CURVE_SIZE,
        }}
        onClick={onClick}
      ></div>
    );
  });

  // Array of gaps for the rest of the week.
  // const futureEmpties = Array(Math.max(6 - earlierDaysInWeek, 0))
  //   .fill(0)
  //   .map((_, i) => {
  //     return (
  //       <span
  //         key={i}
  //         style={{
  //           userSelect: "none",
  //           cursor: "default",
  //           width: SQUARE_SIZE,
  //           height: SQUARE_SIZE,
  //         }}
  //       >
  //         a
  //       </span>
  //     );
  //   });

  const futureEmpties = Array(
    Math.max(STREAK_DATES_TO_SHOW - stabilityWithHash.length, 0)
  )
    .fill(0)
    .map((_, i) => {
      return (
        <div
          key={i}
          style={{
            userSelect: "none",
            cursor: "default",
            width: SQUARE_SIZE,
            height: SQUARE_SIZE,
          }}
        ></div>
      );
    });

  const borderStyle = "1px solid transparent";

  const barRadiusAbove = stabilityAbove === null ? CURVE_SIZE : 0;
  const barRadiusBelow = stabilityBelow === null ? CURVE_SIZE : 0;
  const borderAbove = stabilityAbove === null ? borderStyle : 0;
  const borderBelow = stabilityBelow === null ? borderStyle : 0;

  return (
    <div
      style={{
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "fit-content",
        width: "fit-content",
      }}
    >
      <div
        style={{
          height: "fit-content",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          width: "fit-content",
          backgroundColor: COLOR_EMPTY_BAR,
          marginRight: "0.5rem",
          borderRadius: `${barRadiusAbove} ${barRadiusAbove} ${barRadiusBelow} ${barRadiusBelow}`,
          borderLeft: borderStyle,
          borderRight: borderStyle,
          borderTop: borderAbove,
          borderBottom: borderBelow,
          backgroundClip: "padding-box",
        }}
      >
        {marks.reverse()}
        {futureEmpties}
        <div
          style={{
            content: "",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -1,
            marginTop: borderAbove ? -1 : 0,
            marginBottom: borderBelow ? -1 : 0,
            marginLeft: -1,
            marginRight: -1,
            borderRadius: "inherit",
            background: `linear-gradient(to right, rgba(200, 200, 200, ${0.5}), rgba(200, 200, 200, ${1}))`,
          }}
        />
      </div>
    </div>
  );
};

export default ItemStreak;

import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";

export interface GuideDialogProps {
  content?: Array<string | JSX.Element>;
  open: boolean;
  onClose: () => void;
  title?: string;
  action?: string;
  noAction?: boolean;
  children?: React.ReactNode;
}

const makeParagraph = (words, idx) => {
  if (typeof words === "string") {
    return <Typography key={idx}>{words}</Typography>;
  }
  return <div key={idx}>{words}</div>;
};

const GuideDialog: React.FC<GuideDialogProps> = ({
  onClose,
  open,
  content,
  children,
  title,
  action,
  noAction,
}) => {
  const radioGroupRef = React.useRef<HTMLElement>(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleOk = () => {
    onClose();
  };

  const titleComponent = title ? (
    <Typography align="center" marginTop={"2rem"} variant="h3">
      {title}
    </Typography>
  ) : null;

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 600 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      keepMounted
    >
      {titleComponent}
      <DialogContent>
        {content && content.map(makeParagraph)}
        {children}
      </DialogContent>
      {noAction !== true && (
        <DialogActions>
          <Button onClick={handleOk}>{action || "Ok"}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default GuideDialog;

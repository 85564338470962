import { format } from "date-fns";
import { startOfDay } from "date-fns/esm";
import seedrandom from "seedrandom";

import { findDescendants, findParent } from "./data-structures";
import compact from "lodash/compact";
import size from "lodash/size";

// So that the "next day" happens at 4 AM instead of 0 AM.
const NEW_DAY_OFFSET_MILLIS = 4 * 60 * 60 * 1000;

// Need to refactor and handle these correctly.
const getLocalHabitDate = (datetime) => {
  const tzOffset = 60 * new Date().getTimezoneOffset() * 1000; //offset in milliseconds
  return new Date(datetime.getTime() - tzOffset - NEW_DAY_OFFSET_MILLIS);
};

export const getLocalHabitDateStr = (datetime) => {
  return getLocalHabitDate(datetime).toISOString().split("T")[0];
};

export const getCalendarLocalDateStr = (datetime) => {
  const tzOffset = 60 * new Date().getTimezoneOffset() * 1000; //offset in milliseconds
  return new Date(datetime.getTime() - tzOffset).toISOString().split("T")[0];
};

// Remove this?
const formatSimpleDateStr = (date) => {
  return format(date, "yyyy-MM-dd");
};

export const getLocalHabitDateDisplayStr = (datetime) => {
  return format(getLocalHabitDate(datetime), "MMM do");
};

const parseSimpleDateStr = (str) => {
  const parts = str.split("-");
  // Please pay attention to the month (parts[1]); JavaScript counts months from 0.
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

const isNightTime = (datetime) => {
  return getLocalHabitDateStr(datetime) !== getCalendarLocalDateStr(datetime);
};

function getDaysBetween(date1, date2) {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - date2);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}

// 1-indexed day count for checkpoints.
const CHECKPOINTS = [3, 7, 14, 30, 60];

const getNextCheckpoint = (dayNum) => {
  return CHECKPOINTS.find((checkpointNum) => checkpointNum > dayNum);
};

const getDoneHabitIcon = (startOfToday, sortlyLevel) => {
  const hash = getItemHash(sortlyLevel.id, startOfToday);
  return sortlyLevel.value ? `${getItemSuccessIcon(hash)}` : "◾";
};

const getTodayString = (startOfToday, sortlyLevels) => {
  const lines = sortlyLevels.map((sortlyLevel, index) => {
    const parent = findParent(sortlyLevels, index);
    if (parent && (parent as any).isCollapsed) {
      return null;
    }
    const levelPrefix = new Array(sortlyLevel.depth).fill("\t").join("");

    const hasDescendants = findDescendants(sortlyLevels, index).length > 0;
    const icon = getDoneHabitIcon(startOfToday, sortlyLevel);
    const routinePostfix =
      hasDescendants && sortlyLevel.isCollapsed ? "(routine)" : "";
    return `${levelPrefix}${icon}  ${sortlyLevel.name} ${routinePostfix}`;
  });
  return compact(lines).join("\n");
};

const getFullTodayText = (nowDateTime, sortlyLevels) => {
  const startOfToday = startOfDay(nowDateTime);

  const labelText = `my diligent.day ${getLocalHabitDateDisplayStr(nowDateTime)}\n`;
  return labelText + getTodayString(startOfToday, sortlyLevels);
};

const getItemHash = (habitId, entryStartOfDay) => {
  const entryDateStr = formatSimpleDateStr(entryStartOfDay);
  const rng = seedrandom(habitId + entryDateStr);
  return rng.quick();
};

const RARITIES = [0, 0.94, 0.98];

const getItemSuccessRarity = (hash) => {
  if (hash > RARITIES[2]) {
    return 2;
  }
  if (hash > RARITIES[1]) {
    return 1;
  }
  return 0;
};

const getItemSuccessIcon = (hash) => {
  if (hash > 0.98) {
    const selected = Math.floor(hash * 1000) % 10;
    switch (selected) {
      case 0:
        return "🐖";
      case 1:
        return "🦙";
      case 2:
        return "🐏";
      case 3:
        return "🦘";
      case 4:
        return "🐄";
      case 5:
        return "🐐️";
      case 6:
        return "🐘️";
      case 7:
        return "🦍";
      default:
        return "🐿️";
    }
  }
  if (hash > 0.94) {
    const selected = Math.floor(hash * 1000) % 10;
    switch (selected) {
      case 0:
        return "🪷";
      case 1:
        return "🌺";
      case 2:
        return "🌸";
      case 3:
        return "🏵️";
      case 4:
        return "🌵";
      case 5:
        return "🍀";
      case 6:
        return "🌾️";
      case 7:
        return "🎋️";
      default:
        return "🌹";
    }
  }
  if (hash > 0.9) {
    return "🌷";
  }
  if (hash > 0.85) {
    return "🌻";
  }
  if (hash > 0.75) {
    return "☘️";
  }
  if (hash > 0.5) {
    return "🌿";
  }

  if (hash < 0.025) {
    return "🐝";
  }
  if (hash < 0.05) {
    return "🌲";
  }
  if (hash < 0.075) {
    return "🌳";
  }
  if (hash < 0.1) {
    return "🦋";
  }

  return "🌱";
};

const isPerfectDay = (sortlyLevels) => {
  if (!sortlyLevels || size(sortlyLevels) === 0) {
    return false;
  }
  return sortlyLevels.every((sortlyLevel) => {
    return sortlyLevel.value === true;
  });
};

export const isParentOnly = (level, nextLevel) => {
  return level.depth === 0 && (!nextLevel || nextLevel?.depth > 0);
};

// See first-diligent-day.js for user explanation.
const isDiligentDay = (sortlyLevels) => {
  const countableLevels = sortlyLevels.filter((sortlyLevel, i, orig) => {
    return !isParentOnly(sortlyLevel, orig[i + 1]);
  });
  // Only allow diligent days if there are habits to complete.
  if (!countableLevels || size(countableLevels) === 0) {
    return false;
  }
  // let missedRoots = 0;
  // let missedBranches = 0;
  // // Scrape out the tree levels info.
  // for (const sortlyLevel of sortlyLevels) {
  //   if (!sortlyLevel.value) {
  //     if (size(sortlyLevels) < 4) {
  //       return false;

  //     }
  //     if (sortlyLevel.depth === 0) {
  //       missedRoots++;
  //     } else {
  //       missedBranches++;
  //     }

  //     if (missedRoots > 1 || missedBranches > 1) {
  //       return false;
  //     }
  //   }
  // }
  const hitLevels = countableLevels.filter((level) => {
    return level.value;
  });
  const hitCount = size(hitLevels);
  return hitCount / size(countableLevels) > 0.66;
};

const ALARM_ID_RANGE = 2147483647;
const getAlarmId = (habitId) => {
  const rng = seedrandom(habitId);
  const notifHash = Math.floor(rng.quick() * ALARM_ID_RANGE);
  return notifHash;
};

const SEEN_PREFIX = "seenPopup_";

export const SWIPABLE_CHILD_STYLE: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
};

export const DAYS_IN_WEEK = 7;

export const MASCOT_TYPES = ["🐶", "🐱"];

export const FB_CONFIG = {
  apiKey: "AIzaSyAYFAX5ybeJo9LuTTY09oRyELW4YB1Kmdo",
  authDomain: "diligent-e0444.firebaseapp.com",
  databaseURL: "https://diligent-e0444.firebaseio.com",
  projectId: "diligent-e0444",
  storageBucket: "diligent-e0444.appspot.com",
  messagingSenderId: "673878064592",
  appId: "1:673878064592:ios:4db87a50ca53dd54cd8dd5",
  measurementId: "G-93YFXSGKL7"
};

export const START_DATE_KEY = "StartDate";

const DEFUNCT_POPUP_KEY = "OnboardStageCompleted";
export const ONBOARD_LEVEL_KEY = SEEN_PREFIX + DEFUNCT_POPUP_KEY;

export const DONE_ONBOARD_LEVEL = 5;
export const isOnboardComplete = (onboardLevel) => {
  return onboardLevel >= DONE_ONBOARD_LEVEL;
};

export const isInStandaloneMode = () =>
window.matchMedia("(display-mode: standalone)").matches ||
window.navigator["standalone"] ||
  document.referrer.includes("android-app://");

export const SECONDARY_BUTTON_STYLE = { opacity: 0.6, textTransform: "lowercase" };

export const NOTIF_ID_D1 = 289374523698;

const getRandomD1Notif = (habitName) => {
  const index = Math.floor(Math.random() * 3);
  return [
    {
      title: "Missed this? 🏳️",
      body: `Make it easy enough to never miss: ${habitName}`,
    },
    {
      title: "Give yourself credit! 🍭",
      body: `It’s never too late: ${habitName}`,
    },
    {
      title: "Improvise. Adapt. Overcome. 🏔️",
      body: `What’s holding you back from “${habitName}”?`,
    },
  ][index];
};

// Always go with same time, next day.
export const generateD1NotifDef = (habitName) => {
  const suggestedTime = new Date();
  suggestedTime.setDate(suggestedTime.getDate() + 1);
  return {
    ...getRandomD1Notif(habitName),
    id: NOTIF_ID_D1,
    // Name of the audio file to play when this notification is displayed. Include the file extension with the filename. On iOS, the file should be in the app bundle. On Android, the file should be in res/raw folder. Recommended format is .wav because is supported by both iOS and Android. Only available for iOS and Android < 26. For Android 26+ use channelId of a channel configured with the desired sound. If the sound file is not found, (i.e. empty string or wrong name) the default system notification sound will be used. If not provided, it will produce the default sound on Android and no sound on iOS.
    sound: "./public/system-default.wav",
    schedule: {
      at: suggestedTime,
      allowWhileIdle: true,
    },
  };
};


export const SQUARE_SIZE = "2.5rem";

export const COLOR_ATTENTION_ACCENT = "#ff6961";
// #64faa0
export const COLOR_SUBTLE_GOOD = "#aaeeaa";
export const COLOR_SUBTLE_GOOD_RGB = "170, 238, 170";
// See index.css and capacitor.config
export const COLOR_INDEX_BACK = "#F1F4F9";
export const COLOR_INDEX_BACK_RGB = "241,244,249";
export const COLOR_SUCCESS_BAR = COLOR_INDEX_BACK
export const COLOR_EMPTY_BAR = "rgba(255, 255, 255, 1)";

export const arrayInsert = (arr, ins, n) => [...arr.slice(0, n), ins, ...arr.slice(n)]
export const arrayRemove = (arr, idx) => arr.filter((_, i) => i !== idx)

export const getApproximateLengthWidthAdjusted = (str) => {
  const baseLength = str.length;
  if (baseLength < 15) {
    return baseLength*1.2;
  }
  const lossFromShortLetters = str.split('').reduce((acc, letter) => {
    if (letter === "i" || letter === "l" || letter === "j") {
      return acc + 0.4;
    }
    if (letter === "t" || letter === 'f' || letter === 'r') {
      return acc + 0.05;
    }
    // maaaybe reduce for space too?
    return acc;
  }, 0);
  return baseLength - lossFromShortLetters;
}

export const SERIF_FAMILY = "'PT Serif', serif"

export const IS_BIG_SCREEN_CSS_KEY = "@media (min-width: 768px)"

export const isBigScreen = () => {
  return window.matchMedia("(min-width: 768px)").matches
}

export const STREAK_DATES_TO_SHOW = isBigScreen() ? 8 : 3

export const BASE_GOALS = ["healthy", "happy", "productive"];

export const getGoalText = (goal) => {
  switch (goal) {
    case BASE_GOALS[0]:
      return "healthier";
    case BASE_GOALS[1]:
      return "happier ";
    case BASE_GOALS[2]:
      return "more productive";
  }
};

export const padArrayRight = (array, length, fill) => { return length > array.length ? array.concat(Array(length - array.length).fill(fill)) : array; }

// Jack: Probably shouldn't be caching this state as a special habit, should be tracked as a paststat instead.
export const StreakHabit = {
  HABIT_ID: "top-streak",
  MAX_STREAK: 1000
}

export const UNLOCK_PROGRESSIONS = [
  {
    label: "Set Identity",
    requiredLevel: 1,
  },
  {
    label: "Accountability Pet",
    requiredLevel: 3,
  },
  {
    label: "Name Your Pet",
    requiredLevel: 7,
  },
];

export interface LocalAlarm {
  localHr: number;
  localMin: number;
}

// Add a bit of extra time to make it feel more satisfying.
export const FILL_DELAY_MS = 100 + 20;

export const TEST_MODE = false;
export const NEXT_BUNDLE_VERSION = '3';
export {
  isPerfectDay,
  isDiligentDay,
  isNightTime,
  getNextCheckpoint,
  getDaysBetween,
  parseSimpleDateStr,
  formatSimpleDateStr,
  getFullTodayText,
  getItemHash,
  getItemSuccessRarity,
  getItemSuccessIcon,
  getAlarmId,
  CHECKPOINTS,
  SEEN_PREFIX,
};

import { useEffect, useRef } from "react";
import ChatItem from "./chat-item";

export default function ChatMessages({
  messages,
  isLoading,
  reload,
  stop,
  hasPending,
}: {
  messages: any[];
  isLoading?: boolean;
  stop?: () => void;
  reload?: () => void;
  hasPending?: boolean;
}) {
  const scrollableChatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollableChatContainerRef.current) {
      scrollableChatContainerRef.current.scrollTop =
        scrollableChatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, hasPending]);

  return (
    <div className="w-full max-w-5xl p-4 rounded-xl">
      <div
        className="flex flex-col gap-1 overflow-auto"
        ref={scrollableChatContainerRef}
      >
        {messages.map((m, i: number) => {
          const topSpace = i > 0 && messages[i - 1].role !== messages[i].role;
          return <ChatItem key={m.id} message={m} topSpace={topSpace} />;
        })}
        {hasPending && (
          <div className="flex items-start gap-1">
            <div className="flex flex-col">
              {/* {paras} */}

              <p className="break-words">
                <span className="bouncing-dots">
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

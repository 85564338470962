export default function ChatItem({ message, topSpace }) {
  // Insert a random '\n\n' into the message for testing
  // const filtered = message.content.replace(
  //   /([^\n]\n[^\n])/g,
  //   `$1\n\n${Math.random()}`
  // );
  // console.log("raw message", filtered);
  // Remove leading and trailing quotes
  const isUser = message.role === "user";
  const isNote = isUser && message.id.startsWith("note");
  const userStyling = isUser
    ? "mr-1 break-words text-right"
    : "break-words bg-gray-100 rounded-xl p-2";
  // Remove leading and trailing quotes
  // let displayContent = message.content.replace(/^"|"$/g, "");
  // let displayContent = message.content;
  // Replace any escaped quotes with quotes
  // displayContent = displayContent.replace(/\\"/g, '"');
  // Split on newlines and make each line a paragraph
  // const paras = displayContent.split("\\n").map((str) => {
  //   // console.log("proc ", str);
  //   // Split on quotes and make the quote contents bolded spans
  //   const displayHtml = str.split('"').map((str, i) => {
  //     if (i % 2 === 0) {
  //       return str;
  //     }

  //     return <span className="font-bold">"{str}"</span>;
  //   });
  //   return <p className={userStyling}>{displayHtml}</p>;
  // });
  const spaceStyling = topSpace ? " mt-4" : "";
  const alignStyling = isUser ? " self-end" : "";
  const italicStyling = isUser
    ? isNote
      ? " text-gray-400 italic"
      : " text-gray-600"
    : " text-gray-900";

  const blockStyle =
    "flex items-start gap-1" + spaceStyling + alignStyling + italicStyling;

  return (
    <div className={blockStyle}>
      <p className={userStyling}>
        {isNote ? message.createdAt : ""}
        {message.content}
      </p>
    </div>
  );
}
